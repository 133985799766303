import styles from "./styles.module.scss";
import heart from "@assets/img/emoji/heart.png";
import calm from "@assets/img/emoji/calm.png";
import tearful from "@assets/img/emoji/tearful.png";
import anxious from "@assets/img/emoji/anxious.png";

import { IconCard } from "../../../../shared";

import { useNavigate } from "react-router";

const data = [
  {
    value: "Completely satisfied ",
    displayValue: "Completely satisfied ",
    emoji: heart,
  },
  {
    value: "I love my body, but want to improve it",
    displayValue: "I love my body, but want to improve it",
    emoji: calm,
  },
  {
    value: "I feel somewhat unsatisfied",
    displayValue: "I feel somewhat unsatisfied",
    emoji: tearful,
  },
  {
    value: "Completely dissatisfied",
    displayValue: "Completely dissatisfied",
    emoji: anxious,
  },
];
export const BodyImage = ({ onNextStep }) => {
  const navigate = useNavigate();

  const handleNextStep = () => {
    onNextStep?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How satisfied are you with your body image right now?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={(v) => handleNextStep(v)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
