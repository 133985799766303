import styles from "./styles.module.scss";
import finger from "@assets/img/emoji/fingerup.png";
import { Button } from "../../../../../../common";
import { closeModal } from "../../../../../../utils/modal";

export const Modal = ({ onContinue }) => {
  const handleContinue = () => {
    closeModal();
    onContinue();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.icon}>
        <img src={finger} alt="icon" />
      </div>
      <h4>Thanks for sharing!</h4>
      <p>
        We’ve found that many people have faced the same issues. You will get a
        clear workout plan that is easy to follow. It consists of safe exercises
        that are tailored to your level.
      </p>
      <Button onClick={handleContinue}>Continue</Button>
    </div>
  );
};
