import { useParams } from "react-router";
import styles from "./styles.module.scss";
import { Steps, groupTitles } from "../data/steps";

export const ProgressBar = () => {
  const { stepIndex } = useParams();
  const currentStepIndex = parseInt(stepIndex, 10) - 1;

  const currentGroupIndex = Steps[currentStepIndex]?.groupIndex;
  const currentGroupSteps = Steps.filter(
    (step) => step.groupIndex === currentGroupIndex && !step.excludeFromProgress
  );

  const localStepIndex = currentGroupSteps.findIndex(
    (step) => Steps.indexOf(step) === currentStepIndex
  );

  const stepGroupProgress = () => {
    return ((localStepIndex + 1) / currentGroupSteps.length) * 100;
  };

  const totalGroups = [
    ...new Set(
      Steps.filter(
        (step) => step.groupIndex !== undefined && !step.excludeFromProgress
      ).map((step) => step.groupIndex)
    ),
  ];

  return (
    <div className={styles.progressBarContainer}>
      <h3>{groupTitles[currentGroupIndex]}</h3>
      <div className={styles.progressBars}>
        {totalGroups.map((index) => (
          <div key={index} className={styles.progressBarItem}>
            {currentGroupIndex >= index && (
              <div
                style={{
                  width: `${
                    currentGroupIndex === index ? stepGroupProgress() : 100
                  }%`,
                }}
                className={styles.fill}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
