import styles from "./styles.module.scss";

import like from "@assets/img/emoji/like.png";
import backissue from "@assets/img/emoji/backissue.png";
import massage from "@assets/img/emoji/massage.png";
import sleepy from "@assets/img/emoji/sleepy.png";

export const Benefits = () => {
  return (
    <div className={styles.benefits}>
      <p className={styles.title}>Highlights of your plan</p>
      <div className={styles.list}>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <img src={like} />
          </div>
          <p>
            <strong>Slim toned body</strong> break the plateau and finally lose
            unwanted weight
          </p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <img src={backissue} />
          </div>

          <p>
            <strong>Reduced tension</strong> in your hips, back, sciatica,
            shoulders, neck and jaw
          </p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <img src={massage} />
          </div>

          <p>
            <strong>Reduced stress</strong> & worry and feel more relaxed and
            calm
          </p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <img src={sleepy} />
          </div>

          <p>
            <strong>Improved sleep</strong> <br /> and better quality of rest
          </p>
        </div>
      </div>
    </div>
  );
};
