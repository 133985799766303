import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BottomSheetContainer } from "../shared/bottomSheet";
import classes from "./styles.module.scss";
import "react-circular-progressbar/dist/styles.css";
import {
  getTrainingProgress,
  getTrainings,
} from "../../../redux/slices/exerciseSlice";
import { TrainingsHeader } from "./header";
import { TrainingPreview } from "./preview";
import { TrainingDays } from "./trainingDays";
import { Advices } from "../shared/advices";
import getUserCurrentProgress from "../../../utils/getUserCurrentProgress";
import { ProfileLayout } from "../layout";
import { TrainingContent } from "./content";
import { Loader } from "../../../common/loader";

export const Profile = () => {
  const dispatch = useDispatch();

  const { allExercieses, userDaysProgress, isLoading } = useSelector(
    (state) => state.exerciseSlice
  );

  const [activeDayIndex, setActiveDayIndex] = useState();
  const [activeDay, setActiveDay] = useState();
  const [exerciseAdvice, setExerciseAdvice] = useState();

  const [openAdvicesBottomSheet, setOpenAdvicesBottomSheet] = useState(false);

  const progress = getUserCurrentProgress(userDaysProgress, allExercieses);

  useEffect(() => {
    if (userDaysProgress > allExercieses.length) {
      setActiveDayIndex(allExercieses?.length);
      return;
    }
    setActiveDayIndex(userDaysProgress);
  }, [userDaysProgress, allExercieses]);

  useEffect(() => {
    if (userDaysProgress > allExercieses.length) {
      setActiveDay(allExercieses?.[allExercieses.length - 1]);
      return;
    }
    setActiveDay(allExercieses[userDaysProgress - 1]);
  }, [userDaysProgress, allExercieses]);

  useEffect(() => {
    dispatch(getTrainingProgress());
    dispatch(getTrainings());
  }, []);

  const isCompleted = userDaysProgress > activeDayIndex;

  const handleChangeActiveDay = (workout, index) => {
    setActiveDay(workout);
    setActiveDayIndex(index + 1);
  };

  console.log({ activeDayIndex, activeDay, allExercieses, userDaysProgress });

  const handleOpenExercise = (exercise) => {
    setExerciseAdvice(exercise);
    setOpenAdvicesBottomSheet(true);
  };

  return (
    <ProfileLayout title="Workout plan">
      <main className={classes.main}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TrainingsHeader progress={progress} />
            <TrainingPreview
              isCompleted={isCompleted}
              activeDay={activeDay}
              activeDayIndex={activeDayIndex}
              userDaysProgress={userDaysProgress}
            />
            <TrainingDays
              userDaysProgress={userDaysProgress}
              onChangeDay={handleChangeActiveDay}
              allExercieses={allExercieses}
              activeDayIndex={activeDayIndex}
            />
            <TrainingContent
              activeDay={activeDay}
              onOpenExercise={handleOpenExercise}
            />
          </>
        )}

        <BottomSheetContainer
          open={openAdvicesBottomSheet}
          setOpen={setOpenAdvicesBottomSheet}
        >
          <Advices
            steps={exerciseAdvice?.steps}
            videos={[exerciseAdvice?.video]}
          />
        </BottomSheetContainer>
      </main>
    </ProfileLayout>
  );
};
