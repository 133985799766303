// Libs
import * as yup from "yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";

// Components
import ForgetPasswordModal from "./ForgetPasswordModal";
import SubmitButton from "../../common/SubmitButton";

// API
import authService from "../../api/services/authService";

import styles from "./styles.module.scss";
import { Button } from "../../common";
import { successToast } from "../../utils/toaster";

const emailSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("E-mail is required!")
      .matches(
        /[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/g,
        "Incorrect email. Please try again"
      ),
  })
  .required();

const pinSchema = yup.object({
  pin: yup.string().required(),
});

const AuthPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isPinStep, setIsPinStep] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (authService.getCurrentUser()) {
      navigate("/profile", { replace: true });
    }
  }, [navigate]);

  const emailForm = useForm({ resolver: yupResolver(emailSchema) });
  const pinForm = useForm({ resolver: yupResolver(pinSchema) });

  const onSubmitEmail = async ({ email }) => {
    const { error } = await authService.sendPinToEmail(email);
    if (error) {
      emailForm.setError("email", error.response.data.message);
      return;
    }
    successToast({ message: "Pin has been sent" });
    emailForm.reset();
    setEmail(email);
    setIsPinStep(true);
  };

  const onSubmitPin = async ({ pin }) => {
    const { error, data } = await authService.emailAndPinSignIn(email, pin);
    if (error) {
      pinForm.setError("pin", { message: error.response.data.message });
      return;
    }
    localStorage.setItem("token", data.token);
    navigate("/profile");
  };

  const submitEmail = emailForm.handleSubmit(onSubmitEmail);
  const submitPin = pinForm.handleSubmit(onSubmitPin);

  const emailErrors = emailForm.formState?.errors?.email;
  const pinErrors = pinForm.formState?.errors?.pin;

  const toogleModal = () => setModalOpen((bool) => !bool);

  console.log(pinForm);

  return (
    <div className={styles.container}>
      <h1>Login</h1>

      {!isPinStep ? (
        <form id="email" onSubmit={submitEmail} className={styles.form}>
          <div className={styles.inputContainer}>
            <input
              placeholder="Email"
              type="email"
              {...emailForm.register("email")}
              className={emailErrors ? styles.errorInput : ""}
            />
            <ErrorMessage
              errors={emailForm.formState?.errors}
              name="email"
              render={({ message }) => (
                <p className={styles.errorMessage}>{message}</p>
              )}
            />
          </div>
          <Button form="email" type="submit" disabled={emailForm.isSubmitting}>
            Get Pin
          </Button>
        </form>
      ) : (
        <form id="pin" onSubmit={submitPin} className={styles.form}>
          <div className={styles.inputContainer}>
            <input
              placeholder="Pin"
              type="text"
              {...pinForm.register("pin")}
              className={pinErrors ? styles.errorInput : ""}
            />
            <ErrorMessage
              errors={pinForm.formState?.errors}
              name="pin"
              render={({ message }) => (
                <p className={styles.errorMessage}>{message}</p>
              )}
            />
          </div>
          <Button form="pin" type="submit" disabled={pinForm.isSubmitting}>
            Log In
          </Button>
        </form>
      )}
      <div className={styles.description}>
        By submitting you agree to our <a target="_blank" rel="referrer" href="/privacy-policy">Privacy Policy</a>. Your
        personal information is safe with us.
      </div>
      {modalOpen && <ForgetPasswordModal toogleModal={toogleModal} />}
    </div>
  );
};

export default AuthPage;
