import { OnboardingLayout } from "@common/onboardingLayout";
import { Outlet } from "react-router";

export { CreatingPlan } from "../stepper/questions/creatingPlan";
export { PreRegistration } from "../stepper/questions/preRegistration";
export { Email } from "../stepper/questions/email";
export { Name } from "../stepper/questions/name";
export { Prediction } from "../../shared/components/prediction";
export { Confident } from "../stepper/questions/confident";
export { PlanChart } from "../stepper/questions/planChart";
export { Newsletter } from "../stepper/questions/newsletter";

export const FinalQuestionsWrapper = () => {
  return (
    <OnboardingLayout>
      <Outlet />
    </OnboardingLayout>
  );
};
