import { Prediction } from "../../finalQuestions";
import {
  DesiredResult,
  FitnessLevel,
  FocusAreas,
  PeopleChosen,
  StartJourney,
  MainGoal,
  DreamBody,
  WeightFluctulate,
  SlimDown,
  PeakShape,
  BurnFat,
  Flexible,
  Breathing,
  IssuesStruggling,
  ReduceTension,
  ExcerciseFrequency,
  Walks,
  WorkLife,
  UsualDay,
  EnergyLevels,
  WaterIntake,
  Sleep,
  Breakfast,
  Lunch,
  Dinner,
  DietTypes,
  NutritionStatements,
  PastEvents,
  Height,
  Weight,
  WeightGoal,
  Age,
  Report,
  FutureEvent,
  EventDate,
} from "../questions";

export const groupTitles = [
  "My Profile",
  "Activity",
  "Lifestyle & Habits",
  "Nutrition",
  "Almost Done",
];

export const Steps = [
  { groupIndex: 0, component: StartJourney },
  { groupIndex: 0, component: PeopleChosen },
  {
    groupIndex: 0,
    component: DesiredResult,
    mappingKey: "qqGoal",
  },
  { groupIndex: 0, component: MainGoal },
  { groupIndex: 0, component: DreamBody },
  {
    groupIndex: 0,
    component: WeightFluctulate,
    mappingKey: "qqWeightFluctuation",
  },
  { groupIndex: 0, component: SlimDown },
  { groupIndex: 0, component: PeakShape },
  {
    groupIndex: 1,
    component: FitnessLevel,
    mappingKey: "qqFitnessLevel",
  },
  { groupIndex: 1, component: FocusAreas },
  { groupIndex: 1, component: BurnFat },
  {
    groupIndex: 1,
    component: Flexible,
    mappingKey: "qqFlexibility",
  },
  {
    groupIndex: 1,
    component: Breathing,
    mappingKey: "qqStairs",
  },
  { groupIndex: 1, component: IssuesStruggling },
  { groupIndex: 1, component: ReduceTension },
  {
    groupIndex: 1,
    component: ExcerciseFrequency,
    mappingKey: "qqExerciseFrequency",
  },
  {
    groupIndex: 1,
    component: Walks,
    mappingKey: "qqWalksFrequency",
  },
  {
    groupIndex: 2,
    component: WorkLife,
    mappingKey: "qqWorkLife",
  },
  {
    groupIndex: 2,
    component: UsualDay,
    mappingKey: "qqDayRoutine",
  },
  {
    groupIndex: 2,
    component: EnergyLevels,
    mappingKey: "qqEnergyLevels",
  },
  {
    groupIndex: 2,
    component: WaterIntake,
    mappingKey: "qqDailyWaterIntake",
  },
  { groupIndex: 2, component: Sleep, mappingKey: "qqSleep" },
  {
    groupIndex: 3,
    component: Breakfast,
    mappingKey: "qqBreakfastTime",
  },
  {
    groupIndex: 3,
    component: Lunch,
    mappingKey: "qqLunchTime",
  },
  {
    groupIndex: 3,
    component: Dinner,
    mappingKey: "qqDinnerTime",
  },
  { groupIndex: 3, component: DietTypes },
  { groupIndex: 3, component: NutritionStatements },
  { groupIndex: 4, component: PastEvents },
  {
    groupIndex: 4,
    component: Height,
    mappingKey: "qqHeightCm",
  },
  {
    groupIndex: 4,
    component: Weight,
    mappingKey: "qqWeightKg",
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    mappingKey: "qqGoalWeightKg",
  },
  { groupIndex: 4, component: Age, mappingKey: "qqAge" },
  { groupIndex: 4, component: Report },
  {
    groupIndex: 4,
    component: FutureEvent,
    mappingKey: "qqOccasion",
  },
  {
    groupIndex: 4,
    component: EventDate,
    mappingKey: "qqOccasionDate",
  },
  { groupIndex: 4, component: Prediction },
];
