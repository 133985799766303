import styles from "./styles.module.scss";
import { useState } from "react";
import { Button } from "../../../../../../common";
import { Checkbox, CheckboxChecked } from "@assets/Checkbox";
import full from "./img/full.png";
import hips from "./img/hips.png";
import jaw from "./img/jaw.png";
import lowerback from "./img/lowerback.png";
import neck from "./img/neck.png";
import shoulders from "./img/shoulders.png";
import { generateClassList } from "@utils/generateClassList";

const data = [
  {
    value: "Hips / Pelvis",
    displayValue: "Hips / Pelvis",
    img: hips,
  },
  {
    value: "Lower back",
    displayValue: "Lower back",
    img: lowerback,
  },
  {
    value: "Neck",
    displayValue: "Neck",
    img: neck,
  },
  {
    value: "Shoulders",
    displayValue: "Shoulders",
    img: shoulders,
  },
  {
    value: "Jaw",
    displayValue: "Jaw",
    img: jaw,
  },
];

export const TensionFeel = ({ onNextStep }) => {
  const [checked, setChecked] = useState([]);

  const handleCheck = (value) => {
    setChecked((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item !== value);
      }
      return [...prevState, value];
    });
  };

  const handleNextStep = () => {
    const dataToSend = checked.length === data.length ? "Full body" : checked;
    console.log(dataToSend);
    onNextStep(dataToSend);
  };

  const handleCheckAll = () => {
    if (checked.length === data.length) {
      setChecked([]);
      return;
    }
    setChecked(data.map((i) => i.value));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Where do you usually feel the most tension? </h2>
        <div className={styles.cardsWithImage}>
          <div className={styles.cards}>
            {data.map((value) => (
              <div
                onClick={() => handleCheck(value.value)}
                className={generateClassList(
                  styles.card,
                  { checked: checked.includes(value.value) },
                  styles
                )}
                key={value.value}
              >
                <div className={styles.text}>
                  <div className={styles.checkbox}>
                    {checked.includes(value.value) ? (
                      <CheckboxChecked />
                    ) : (
                      <Checkbox />
                    )}
                  </div>
                  <div className={styles.label}>{value.displayValue}</div>
                </div>
                <div className={styles.img}>
                  <img src={value.img} alt={value.displayValue} />
                </div>
              </div>
            ))}
            <div
              onClick={handleCheckAll}
              className={generateClassList(
                styles.card,
                { checked: checked.length === data.length },
                styles
              )}
            >
              <div className={styles.text}>
                <div className={styles.checkbox}>
                  {checked.length === data.length ? (
                    <CheckboxChecked />
                  ) : (
                    <Checkbox />
                  )}
                </div>
                <div className={styles.label}>All over my body</div>
              </div>
              <div className={styles.img}>
                <img src={full} alt="Full body" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button disabled={!checked.length} onClick={handleNextStep}>
          Continue
        </Button>
      </div>
    </div>
  );
};
