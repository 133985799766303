import NiceModal, { useModal } from "@ebay/nice-modal-react";
import styles from "./styles.module.scss";
import { useRef } from "react";

export const ModalContainer = NiceModal.create(({ children, onClose }) => {
  const modalRef = useRef(null);
  const modal = useModal();

  return modal.visible ? (
    <div className={styles.modalOverlay}>
      <div ref={modalRef} className={styles.content}>
        {children}
      </div>
    </div>
  ) : null;
});
