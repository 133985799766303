import { BreakdownWithEmoji } from "../../../../shared";
import { SOMATIC_KEYS } from "../../../../shared";
import like from "@assets/img/emoji/like.png";

const dataAges = {
  "18-29": "20s",
  "30-39": "30s",
  "40-49": "40s",
  "50+": "50s",
};

function formatPhrases(phrases) {
    const lowercasedPhrases = phrases.map(phrase => phrase.toLowerCase());

    if (lowercasedPhrases.length === 1) {
      return lowercasedPhrases[0];
    } else if (lowercasedPhrases.length === 2) {
      return lowercasedPhrases.join(' and ');
    } else {
      const allButLastTwo = lowercasedPhrases.slice(0, -2).join(', ');
      const lastTwo = lowercasedPhrases.slice(-2).join(' and ');
      return `${allButLastTwo}, ${lastTwo}`;
    }
  }

export const TransformBody = ({onNextStep}) => {
  const ages = localStorage.getItem(SOMATIC_KEYS.ageStart);
  const value = localStorage.getItem(SOMATIC_KEYS.hopeToAchieve);
  const options = JSON.parse(value);
  const content = formatPhrases(options);
  const title = "Transform your body with the power of mindful exercise";
  const paragraph = [
    `For woman in their ${dataAges[ages]} somatic workouts are a great tool to <strong>${content}.</strong>`,
    "It directly interacts with the nervous system to relieve chronic muscle tension and alleviate pent-up stress.",
  ];
  return (
    <BreakdownWithEmoji
      title={title}
      paragraph={paragraph}
      emoji={like}
      onContinue={() => onNextStep()}
    />
  );
};
