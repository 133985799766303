import styles from "./styles.module.scss";

export const Title = () => (
  <div className={styles.header}>
    <h2>Exclusive Sign-Up Offer</h2>
    <p>
      Before your transactions is processed in the next 5 minutes, you can add
      an offer to your plan at up to 65% off.
    </p>
  </div>
);
