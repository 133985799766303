import { Done } from "@assets/Icons";
import styles from "./styles.module.scss";

export const Stepper = () => {
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar}>
        <div>
          <div className={`${styles.circle} ${styles.completed}`}>
            <Done />
          </div>
        </div>
        <div className={styles.progressBarSegment} />
        <div className={`${styles.circle} ${styles.current}`}>2</div>
        <div className={styles.progressBarSegment} />
        <div className={`${styles.circle} `}>3</div>
      </div>
      <div className={styles.stepsTitles}>
        <p>Buy plan</p>
        <p>
          <strong>Add offer</strong>
        </p>
        <p>
          <span>Access plan</span>
        </p>
      </div>
    </div>
  );
};
