import { useEffect } from "react";
import { getTrainings, getWorkouts } from "../../../redux/slices/exerciseSlice";
import { useSelector } from "react-redux";
import { ProfileLayout } from "../layout";

import classes from "./styles.module.scss";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Loader } from "../../../common/loader";

export const Workouts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, workouts } = useSelector((state) => state.exerciseSlice);

  useEffect(() => {
    dispatch(getWorkouts());
    dispatch(getTrainings());
  }, []);

  return (
    <ProfileLayout title="Workouts">
      {isLoading ? (
        <Loader />
      ) : (
        <main className={classes.workouts}>
          <p className={classes.subtitle}>Let's get your body moving</p>
          <div className={classes.cards}>
            {workouts?.map((item) => (
              <div
                onClick={() => navigate(`/workouts/${item.id}`)}
                key={item.name}
                className={classes.card}
                style={{
                  backgroundImage: `url(${item.previewPicture})`,
                }}
              >
                <h5>{item.name}</h5>
                <p>
                  {item.workouts.length}{" "}
                  {item.workouts.length === 1 ? "Workout" : "Workouts"}
                </p>
              </div>
            ))}
          </div>
        </main>
      )}
    </ProfileLayout>
  );
};
