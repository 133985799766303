import { Scale } from "@assets/Icons";
import { ftToCm, kgToLbs, lbsToKg } from "@utils/measurements";
import styles from "./styles.module.scss";

export const GoalHint = ({ weight, height, weightGoal }) => {
  const ms = localStorage.getItem("ms");
  const heightMS = ms === "us" ? ftToCm(height) : height;
  const isGainWeight = weightGoal > weight;
  const minimumWeight =
    heightMS * heightMS * 0.0004246 + 0.2712 * heightMS - 14.9057;
  const gainWeightPercent = Math.round((weightGoal / weight) * 100) - 100;

  console.log(weight, weightGoal);

  const weightGoalMs = ms === "us" ? lbsToKg(weightGoal) : weightGoal;

  const isCriticalWeight = weightGoalMs <= minimumWeight;
  return isCriticalWeight ? (
    <div className={`${styles.card} ${styles.low}`}>
      <Scale color="#FF6A6A" />
      <h6>Be careful: low weight!</h6>
      <p>
        The minimum allowable weight for your height is{" "}
        <strong>
          {ms === "us"
            ? `${Math.round(kgToLbs(minimumWeight))} lbs`
            : `${Math.round(minimumWeight)} kg`}
        </strong>
        . Anything lower is not recommended by World Health Organization.
      </p>
    </div>
  ) : (
    <div className={styles.card}>
      <Scale />
      <h6>
        Go for it: you can {isGainWeight ? "gain" : "lose"} up to{" "}
        {Math.abs(gainWeightPercent)}% of your weight.
      </h6>
      <p>
        Reducing excess weight decreases the risk of heart disease, helps
        alleviate straining of joints and improved quality of sleep
      </p>
    </div>
  );
};
