import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

import { useDispatch } from "react-redux";
import { getUserInfoThunk } from "../../../../redux/slices/userSlice";

import {
  FAQSection,
  Guarantee,
  Partners,
  PaywallChart,
  PersonalPlan,
  Plan,
  ResultsSlider,
  Reviews,
  Timer,
  Transform,
} from "src/routes/onboarding/shared/components/paywall/components";
import { DiscountProvider } from "src/routes/onboarding/shared/components/paywall/DiscountContext";
import Normal from "../assets/img/normal.png";
import Overweight from "../assets/img/overweight.png";
import Obese from "../assets/img/obese.png";
import Underweight from "../assets/img/underweight.png";
import After from "../assets/img/after.png";
import { ReliefSlider } from "./components/lastingRelief";
import { UpsaleModal } from "./components/upsaleModal";
import { AdditionalDiscount } from "./components/additionalDiscount";
import { Benefits } from "./components/benefits";
import { ProfileCard } from "./components/profileCard";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
  After,
};

export const SomaticPaywall = () => {
  const dispatch = useDispatch();
  const [upsaleModalVisible, setUpsaleModalVisible] = useState(false);

  const faqSectionRef = useRef(null);
  const element1Ref = useRef(null);
  const element2Ref = useRef(null);
  const element3Ref = useRef(null);

  const elements = [element1Ref, element2Ref, element3Ref];

  const name = localStorage.getItem("name");

  //hide upsale
  // const additionalDiscount = localStorage.getItem("additionalDiscount");
  const additionalDiscount = false;

  const handleScrollToClosest = () => {
    const scrollTop = window.scrollY;

    const closestElement = elements.reduce((closest, current) => {
      const currentOffsetTop =
        current.current.getBoundingClientRect().top + scrollTop;
      const closestOffsetTop =
        closest.current.getBoundingClientRect().top + scrollTop;

      const currentDistance = Math.abs(currentOffsetTop - scrollTop);
      const closestDistance = Math.abs(closestOffsetTop - scrollTop);

      return currentDistance < closestDistance ? current : closest;
    });

    closestElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  useEffect(() => {
    dispatch(getUserInfoThunk());
  }, []);

  const handleCheckoutClose = () => {
    //hide upsale
    // setUpsaleModalVisible(true);
    // localStorage.setItem("additionalDiscount", true);
  };

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        <Timer onGetMyPlanClick={handleScrollToClosest} />
        {additionalDiscount && <AdditionalDiscount />}
        <Transform
          images={BMI_TYPE_IMAGES}
          title="Somatic exercise"
          header="After the program"
        />
        <PersonalPlan isSomatic />
        <div ref={element1Ref}>
          <Plan
            title="AI-optimized plan"
            isMetrics
            onCloseCheckout={handleCheckoutClose}
            additionalDiscount={additionalDiscount}
          />
        </div>
        <ProfileCard />
        <PaywallChart />
        <div ref={element2Ref}>
          <Plan
            title={"get visible results in 4 weeks!"}
            name={name}
            onCloseCheckout={handleCheckoutClose}
            additionalDiscount={additionalDiscount}
          />
        </div>
        <Benefits />
        <Partners />
        <ReliefSlider />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <div ref={element3Ref}>
          <Plan
            title="Get visible results in 4 weeks with AI‑optimized plan!"
            isMetrics
            onCloseCheckout={handleCheckoutClose}
            additionalDiscount={additionalDiscount}
          />
        </div>
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
      {upsaleModalVisible && (
        <UpsaleModal onClose={() => setUpsaleModalVisible(false)} />
      )}
    </DiscountProvider>
  );
};
