import styles from "./styles.module.scss";
import { Button } from "@common/index";
import useDiscountCountdown from "@hooks/useDiscountCountdown";

export const Timer = ({ onGetMyPlanClick }) => {
  const {
    time: { displayMinutes, displaySeconds },
  } = useDiscountCountdown();

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timer}>
        <div className={styles.time}>
          <p>{displayMinutes}</p>
          <span>minutes</span>
        </div>
        <p>:</p>
        <div className={styles.time}>
          <p>{displaySeconds}</p>
          <span>seconds</span>
        </div>
      </div>
      <div className={styles.button}>
        <Button onClick={onGetMyPlanClick}>GET MY PLAN</Button>
      </div>
    </div>
  );
};
