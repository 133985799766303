import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import exercisesService from "@api/services/exercisesService";
import { WorkoutContentService } from "@api/services/workoutContentService";
import { TrainingContentService } from "@api/services/trainingContentService";
import { UserTrainingService } from "@api/services/userTrainingService";

const initialState = {
  isLoading: false,
  isLoadingResetProgress: false,
  workouts: [],
  trainings: [],
  allExercieses: [],
  userDaysProgress: 0,
  trainingInfo: null,
  error: null,
  success: false,
  userTrainings: [],
};

const exerciseSlice = createSlice({
  name: "excerciseSlice",
  initialState,
  reducers: {
    logoutUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialUserData.fulfilled, (state, { payload }) => {
      state.userDaysProgress = payload.userDaysProgress + 1;
    });
    builder.addCase(resetUserProgress.pending, (state) => {});
    builder.addCase(resetUserProgress.fulfilled, (state, { payload }) => {
      if (payload === 200) {
        state.userDaysProgress = 1;
      }
      state.isLoadingResetProgress = false;
    });
    builder.addCase(patchUserProgress.fulfilled, (state, { payload }) => {
      if (payload === 200) {
        state.userDaysProgress = state.userDaysProgress + 1;
      }
    });

    builder.addCase(getWorkouts.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isLoading = false;
        state.workouts = payload;
      }
    });
    builder.addCase(getTrainings.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isLoading = false;
        const mappedPayload = payload[0].workouts.map((workout) => {
          let exercises = [];

          workout.sets.forEach(
            (set) => (exercises = [...exercises, ...set.exercises])
          );
          return {
            ...workout,
            exercises,
          };
        });
        state.allExercieses = mappedPayload;
        state.trainingInfo = payload[0];
      }
    });
    builder.addCase(getWorkouts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTrainings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTrainingProgress.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userTrainings = payload;
        if (payload.length === 0) {
          state.userDaysProgress = 1;
        } else {
          state.userDaysProgress = payload[0].progress + 1;
        }
      }
    });
  },
});

export const patchUserProgress = createAsyncThunk(
  "patchUserProgress",
  async (_, { getState }) => {
    const state = getState();

    if (state.slices.userDaysProgress > 27) {
      return;
    }

    const patchProgressStatus = await exercisesService
      .patchProgress(state.slices.userDaysProgress)
      .then((response) => response.status)
      .catch((error) => error);

    return patchProgressStatus;
  }
);

export const resetUserProgress = createAsyncThunk(
  "resetUserProgress",
  async (userProgress) => {
    const resetUserProgressStatus = await exercisesService
      .patchProgress(userProgress)
      .then((response) => response.status)
      .catch((error) => error);

    return resetUserProgressStatus;
  }
);

export const getInitialUserData = createAsyncThunk(
  "getInitialUserData",
  async () => {
    const allExercisesData = exercisesService.getAllExercises();
    const userProgressData = exercisesService.exerciseProgress();

    const [daysProgress, userDayOnProgress] = await Promise.all([
      allExercisesData,
      userProgressData,
    ])
      .then((response) => response)
      .catch((e) => {
        console.log(e);
      });

    return {
      days: daysProgress.days,
      userDaysProgress: userDayOnProgress.dayOn,
    };
  }
);

export const getWorkouts = createAsyncThunk(
  "workouts/getWorkouts",
  async () => {
    try {
      const products = await WorkoutContentService.getUserSubscriptions();
      const responses = await Promise.all(
        products.map((product) => WorkoutContentService.get(product))
      );

      const mappedData = responses.flatMap((res) =>
        res.data.categories.map((category) => ({
          ...category,
          id: category.name.toLowerCase().split(" ").join("_"),
          workouts: res.data.workout.filter((workout) =>
            workout.category
              .map((item) => item.toLowerCase())
              .includes(category.name.toLowerCase())
          ),
        }))
      );
      return mappedData;
    } catch (e) {
      console.log(e);
    }
  }
);

export const getTrainings = createAsyncThunk(
  "workouts/getTrainings",
  async () => {
    try {
      const res = await TrainingContentService.get();
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const updateTraining = createAsyncThunk(
  "user-training/putUserTraining",
  async ({ id, progress }) => {
    try {
      await UserTrainingService.update(id, progress);
    } catch (e) {
      console.log(e);
    }
  }
);

export const getTrainingProgress = createAsyncThunk(
  "user-training/getUserTrainging",
  async () => {
    try {
      const res = await UserTrainingService.get();
      console.log(res);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const { logoutUser } = exerciseSlice.actions;

export default exerciseSlice.reducer;
