import { Outlet, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { OnboardingLayout } from "@common/onboardingLayout";
import { getUserInfoThunk } from "src/redux/slices/userSlice";
import { ProgressBar } from "./progressBar";
import { Steps } from "./data/steps";
import styles from "./styles.module.scss";

export const OnboardingSomaticStepper = () => {
  const dispatch = useDispatch();
  const { stepIndex } = useParams();
  const currentStepIndex = parseInt(stepIndex, 10) - 1;

  const [showProgressBar, setShowProgressBar] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token")) dispatch(getUserInfoThunk());
  }, []);

  useEffect(() => {
    const checkForBreakdown = () => {
      const breakdownElement = document.getElementById("breakdown");
      setShowProgressBar(!breakdownElement);
    };

    const observer = new MutationObserver(checkForBreakdown);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    checkForBreakdown();

    return () => {
      observer.disconnect();
    };
  }, [stepIndex]);

  const currentStep = Steps[currentStepIndex];
  const groupTitle = currentStep?.headerTitle;

  return (
    <OnboardingLayout
      hideNavigateBack={currentStepIndex === 0}
      headerContent={
        groupTitle ? (
          <p className={styles.groupTitle}>{groupTitle}</p>
        ) : undefined
      }
      underHeaderContent={showProgressBar ? <ProgressBar /> : undefined}
    >
      <Outlet />
    </OnboardingLayout>
  );
};
