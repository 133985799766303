import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getWorkouts } from "../../../redux/slices/exerciseSlice";
import keyboardRight from "../../../assets/keyboardRight.svg";
import styles from "./styles.module.scss";
import { Loader } from "../../../common/loader";
import { BottomSheetContainer } from "../shared";
import { Advices } from "../shared/advices";
import { nanoid } from "nanoid";

export const Workout = () => {
  const [open, setOpen] = useState(false);
  const [exercise, setExercise] = useState(null);
  const { title } = useParams();
  const { isLoading, workouts } = useSelector((state) => state.exerciseSlice);
  const workout = workouts.find((item) => item.id === title);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate("/workouts");
  };

  useEffect(() => {
    if (!workout) {
      dispatch(getWorkouts());
    }
  }, []);

  return (
    <div className={styles.workout}>
      <div className={styles.header}>
        <div onClick={handleNavigateBack} className={styles.back}>
          <img src={keyboardRight} alt="back" />
        </div>
        <h4>{workout?.name}</h4>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.groups}>
            {workout?.workouts.map((workout, index) => {
              let exercises = [];
              workout.sets.forEach(
                (set) => (exercises = [...exercises, ...set.exercises])
              );
              return (
                <div className={styles.group}>
                  <div className={styles.groupTitle}>
                    <h5>
                      {workout.name} | <span>{workout.kcal} kcal</span>
                    </h5>
                  </div>
                  <div className={styles.cards}>
                    {exercises.map((exercise) => (
                      <div
                        onClick={() => {
                          if (open) {
                            setOpen(false);
                          } else {
                            setOpen(true);
                            setExercise(exercise);
                          }
                        }}
                        className={styles.card}
                        key={nanoid()}
                      >
                        <img src={exercise.previewPicture} alt="" />
                        <div className={styles.cardTitle}>
                          <h5>{exercise?.name}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <BottomSheetContainer open={open} setOpen={setOpen}>
            <Advices steps={exercise?.steps} videos={[exercise?.video]} />
          </BottomSheetContainer>
        </>
      )}
    </div>
  );
};
