import styles from "./styles.module.scss";
import { Garant } from "../../../../../../../assets/Icons";

export const Guarantee = () => {
  return (
    <div className={styles.moneyBack}>
      <div className={styles.icon}>
        <Garant />
      </div>
      <h2 className={styles.title}>30-day money-back guarantee</h2>
      <p className={styles.paragraph}>
        We believe that our plan may work for you and you’ll get visible results
        in 4 weeks! We even are ready to return your money back if you don’t see
        visible results and can demonstrate that you followed our plan.
        <br />
        <br />
        Find more about applicable limitations in our 
        <a
          href="https://www.easyfit.me/subscription-policy"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          subscription policy
        </a>
        .
      </p>
    </div>
  );
};
