import { BreakdownWithEmoji } from "../../../../shared";
import applouse from "@assets/img/emoji/applouse.png";



export const ThankForSharing = ({ onNextStep }) => {
  const paragraph = [
    "For now your plan will be created for your whole body, your answers will help us understand your needs better, so that we can <strong>personalize your new plan</strong> better.",
  ];
  return (
    <BreakdownWithEmoji
      title="Thank you for sharing!"
      paragraph={paragraph}
      emoji={applouse}
      onContinue={() => onNextStep()}
    />
  );
};
