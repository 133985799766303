import styles from "./styles.module.scss";
import lean from "./img/lean.png";
import toned from "./img/toned.png";
import average from "./img/average.png";
import few_pounds_less from "./img/few_pounds_less.png";

import { ImageCard, SOMATIC_KEYS } from "../../../../shared";

const data = [
  {
    value: "Lean",
    displayValue: "Lean",
    emoji: lean,
  },
  {
    value: "Toned",
    displayValue: "Toned",
    emoji: toned,
  },
  {
    value: "Average",
    displayValue: "Average",
    emoji: average,
  },
  {
    value: "Few pounds less",
    displayValue: "Few pounds less",
    emoji: few_pounds_less,
  },
];
export const CompositionWantToHave = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    console.log(v);
    localStorage.setItem(SOMATIC_KEYS.targetBody, JSON.stringify(v));
    onNextStep?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What body composition do you want to have?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <ImageCard
              onClick={() => handleNextStep(value.value)}
              img={value.emoji}
              text={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
