import { OptionsWithBackground, SOMATIC_KEYS } from "../../../../shared";
import background from "./bg.png";

const dataLooseWeight = [
  {
    displayValue: "That’s all I want",
    value: "1",
  },
  {
    value: "2",
    displayValue: "Yes, I’d like to try",
  },
  {
    value: "3",
    displayValue: "Weight loss is too complicated for me",
  },
];

const dataGetFit = [
  {
    displayValue: "That’s all I want",
    value: "1",
  },
  {
    value: "2",
    displayValue: "Yes, I’d like to try",
  },
  {
    value: "3",
    displayValue: "Muscle gain is too complicated for me",
  },
];

export const ReadyToLose = ({ onNextStep }) => {
  const readyToLoseAnswer = localStorage.getItem(SOMATIC_KEYS.weightChange);
  const isStruggleToGain = readyToLoseAnswer === "STRUGGLE_WITH_WEIGHT_GAIN";
  const data = isStruggleToGain ? dataGetFit : dataLooseWeight;
  const title = isStruggleToGain
    ? "Are you ready to finally get fit and gain muscle?"
    : "Are you ready to finally lose the unwanted weight?";
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
  };
  return (
    <OptionsWithBackground
      data={data}
      title={title}
      onClick={handleNextStep}
      background={background}
    />
  );
};
