import { Outlet, useParams } from "react-router";
import { OnboardingLayout } from "../../../../common";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserInfoThunk } from "../../../../redux/slices/userSlice";
import { ProgressBar } from "./progressBar";

export const OnboardingStepper = () => {
  const dispatch = useDispatch();
  const { stepIndex } = useParams();

  const currentStepIndex = parseInt(stepIndex, 10) - 1;

  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    setShowProgressBar(!document.getElementById("breakdown"));
  }, [stepIndex]);

  useEffect(() => {
    if (localStorage.getItem("token")) dispatch(getUserInfoThunk());
  }, []);

  return (
    <OnboardingLayout
      hideNavigateBack={currentStepIndex === 0}
      headerContent={showProgressBar ? <ProgressBar /> : undefined}
    >
      <Outlet />
    </OnboardingLayout>
  );
};
