import { usePreloadImages } from "@hooks/usePreloadImages";
import { DATA } from "./data";
import styles from "./styles.module.scss";
import { DATA as focusAreasData } from "../focusAreas/data";
import body from "../focusAreas/img/body.png";

export const FitnessLevel = ({ onNextStep }) => {
  usePreloadImages([...Object.values(focusAreasData), body]);

  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
  };
  return (
    <div className={styles.container}>
      <h2>What is your fitness level?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, value]) => (
          <div
            onClick={() => handleNextStep(key)}
            key={key}
            className={styles.card}
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
