import styles from "./styles.module.scss";

import Visa from "./img/Visa.png";
import MasterCard from "./img/MasterCard.png";
import Maestro from "./img/Maestro.png";
import Discover from "./img/Discover.png";
import Amex from "./img/Amex.png";
import DinersClub from "./img/DinersClub.png";

export const CardLogos = () => {
  return (
    <div className={styles.cardLogosWrapper}>
      <img src={Visa} alt="Visa" />
      <img src={MasterCard} alt="MasterCard" />
      <img src={Maestro} alt="Maestro" />
      <img src={Discover} alt="Discover" />
      <img src={DinersClub} alt="Diners Club" />
      <img src={Amex} alt="American Express" />
    </div>
  );
};
