import styles from "./styles.module.scss";
import scared from "@assets/img/emoji/scared.png";
import headache from "@assets/img/emoji/headache.png";
import thinking from "@assets/img/emoji/thinking.png";
import angel from "@assets/img/emoji/angel.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { useNavigate } from "react-router";
import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Almost always",
    emoji: scared,
  },
  {
    value: "2",
    displayValue: "Quite often",
    emoji: headache,
  },
  {
    value: "3",
    displayValue: "Rarely",
    emoji: thinking,
  },
  {
    value: "4",
    displayValue: "Almost never",
    emoji: angel,
  },
];
export const BrainFog = ({ onNextStep }) => {

  const handleNextStep = (v) => {
    localStorage.setItem(SOMATIC_KEYS.brainFog, v);
    onNextStep(v);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>How frequently do you experience brain fog?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
