import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { ChartGainWeight, ChartLoseWeight } from "../../../prediction/charts";

export const PaywallChart = () => {
  const user = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const predictedDate = user?.fitnessProfile?.weightGoalCompletionEstimatedDate;

  const isLoseWeight = weight > goal && weight !== goal;

  const averageWeight = Math.round((weight + goal) / 2);
  const gainWeightMiddle = Math.round(weight + (averageWeight - weight) * 1.5);
  const gainWeightEnd = weight + (averageWeight - weight) * 3;

  const axisY = isLoseWeight
    ? [weight, averageWeight, goal]
    : [gainWeightEnd, gainWeightMiddle, weight];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.subtitle}>
            According to the information you have provided us, you'll achieve
            your goal weight of
          </p>
          <p className={styles.predictedData}>
            {goal} {ms === "us" ? "lbs" : "kg"} <span>by</span>{" "}
            {new Date(predictedDate).toLocaleString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
          </p>
        </div>

        <div className={styles.chart}>
          <div className={styles.axisx}>
            <span>
              {" "}
              {new Date().toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
              })}
            </span>
            <span>
              {" "}
              {new Date(predictedDate).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
              })}
            </span>
          </div>
          <div className={styles.axisy}>
            {axisY.map((value, index) => {
              return <span key={index}>{value}</span>;
            })}
          </div>
          <div
            className={
              isLoseWeight
                ? `${styles.label}`
                : `${styles.label} ${styles.gain}`
            }
          >
            Goal <br />
            {goal} {ms === "us" ? "lbs" : "kg"}
          </div>

          {isLoseWeight ? <ChartLoseWeight /> : <ChartGainWeight />}
        </div>
      </div>
    </div>
  );
};
