import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import { Chart } from "./Chart";
import { Back } from "@assets/Icons";

export const LoadingChart = ({ onNextStep }) => {
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          onNextStep();
          return 100;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h2>
        EasyFit is not just about working out, it's about{" "}
        <span>feeling good</span> on the inside and out
      </h2>
      <div className={styles.loaderContainer}>
        <div
          style={{ width: `${progress}%` }}
          className={styles.loaderFill}
        ></div>
        <p>LOADING...{progress}%</p>
      </div>
      <div className={styles.chart}>
        <Chart />
        <div className={styles.radiance}></div>
        <div className={styles.chartLabel}>
          <Back />
          Stress level
        </div>
      </div>
    </div>
  );
};
