export const SOMATIC_KEYS = {
  ageStart: "ageStart",
  mainGoal: "mainGoal",
  hopeToAchieve: "hopeToAchieve",
  feelingStressed: "feelingStressed",
  feelingExhaussted: "feelingExhausted",
  unresolvedEmotions: "unresolvedEmotions",
  brainFog: "brainFog",
  emotioanllyAffected: "emotioanllyAffected",
  lastTimeRelaxed: "lastTimeRelaxed",
  weightChange: "weightChange",
  oftenWorkout: "ofterWorkout",
  bestShape: "bestShape",
  targetBody: "targetBody",
  newsletter: "newsletter",
  name: "name",
  confident: "confident",
};
