import styles from "./styles.module.scss";
import { Lock } from "@assets/Icons";
import { Button } from "@common/index";
import authService from "@api/services/authService";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";
import { SOMATIC_KEYS, useEmail } from "src/routes/onboarding/shared";
import { useNavigate } from "react-router";

const title = "We ask your email to create your account";
const content =
  "Protecting your privacy is a very serious matter for us. Your email is being used to create an account for you so that your data can be saved in it.";

export const Email = () => {
  const navigate = useNavigate();
  const onNextStep = () => {
    navigate("/news");
  };
  const {
    email,
    isValid,
    errorMessage,
    handleEmailChange,
    isLoading,
    onSubmit,
  } = useEmail(authService.emailOnlySignUp, onNextStep);

  const handleSubmit = async () => {
    onSubmit(isValid, email);
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>
          Your 4-week{" "}
          <span>
            Somatic
            <br />
            exercise
          </span>{" "}
          plan is ready
        </h2>
        <div className={styles.input}>
          <input
            placeholder="Your email"
            type="email"
            value={email}
            onChange={(e) => handleEmailChange(e.currentTarget.value)}
            className={isValid}
          />
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}{" "}
          {email ? (
            <div className={styles.greenInfoBanner}>
              <p>★ 4.3 MILLION USERS HAVE CHOSEN US ★</p>
            </div>
          ) : (
            <Tooltip
              icon={fingerup}
              title={title}
              content={content}
              background="info"
            />
          )}
          <div className={styles.hint}>
            <div>
              <Lock />
            </div>
            <p>
              We care about your privacy and are dedicated to protecting your
              personal information.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} disabled={!isValid || isLoading}>
          Continue
        </Button>
      </div>
    </div>
  );
};
