import styles from "./styles.module.scss";

export const FeaturesList = () => (
  <ul className={styles.featuresList}>
    <li>
      <span>★</span>Abs workouts according to your body type
    </li>
    <li>
      <span>★</span>Personalized plans to boost your results
    </li>
    <li>
      <span>★</span>Unique workouts to improve your sexual energy
    </li>
    <li>
      <span>★</span>Auto-renewal plans, cancel anytime
    </li>
  </ul>
);
