import styles from "./styles.module.scss";

export const ChartMaintain = () => {
  return (
    <svg
      width="343"
      height="262"
      viewBox="0 0 343 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8_56)">
        <rect width="343" height="262" fill="#F8F8F8" />

        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 94.5)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 137)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 179.5)"
          fill="#D9D9D9"
        />
        <rect
          width="303"
          height="1"
          transform="matrix(-1 0 0 1 323 222)"
          fill="#D9D9D9"
        />
        <path
          opacity="0.2"
          d="M56 222V140 M140.5 222V140 M223 222V140 M322.5 222V140"
          stroke="#171717"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="2 2"
        />
        <path
          className={styles.grow}
          d="M20 140 L323 140"
          stroke="url(#paint0_linear_8_56)"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className={styles.show}
          d="M20 140 L323 140 L323 222 L20 222 Z"
          fill="url(#paint1_linear_8_56)"
          fill-opacity="0.12"
        />
        <rect
          className={styles.show}
          x="258"
          y="70"
          width="72"
          height="44"
          rx="7"
          fill="#7C8AF7"
        />
        <g filter="url(#filter0_d_8_56)">
          <rect x="4" y="90" width="49" height="27" rx="7" fill="white" />
          <path
            d="M13 115L18.6893 122.876C19.0885 123.43 19.9115 123.43 20.3107 122.876L25 115L13 115Z"
            fill="white"
          />
        </g>
        <path
          className={styles.show}
          d="M321 112L314.23 122.222C313.834 122.643 313.166 122.643 312.77 122.222L306 112H321Z"
          fill="#7C8AF7"
        />

        <g filter="url(#filter1_d_8_56)">
          <circle
            className={styles.show}
            cx="322"
            cy="140"
            r="5"
            fill="white"
            shape-rendering="crispEdges"
          />
          <circle
            className={styles.show}
            cx="322"
            cy="140"
            r="6"
            stroke="black"
            stroke-opacity="0.16"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_8_56)">
          <circle
            cx="21"
            cy="140"
            r="5"
            fill="white"
            shape-rendering="crispEdges"
          />
          <circle
            cx="21"
            cy="140"
            r="6"
            stroke="black"
            stroke-opacity="0.16"
            stroke-width="2"
            shape-rendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8_56"
          x="2"
          y="64"
          width="90"
          height="59"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_56"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_56"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8_56"
          x="307"
          y="124"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_56"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_56"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8_56"
          x="5"
          y="126"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_56"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_56"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8_56"
          x1="20"
          y1="133.386"
          x2="323"
          y2="133.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F91E1E" />
          <stop offset="0.26" stop-color="#FFA51F" />
          <stop offset="0.465" stop-color="#F9ED2A" />
          <stop offset="0.725" stop-color="#64EC82" />
          <stop offset="1" stop-color="#36E6E6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_56"
          x1="20"
          y1="63"
          x2="308.5"
          y2="212.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F91E1E" />
          <stop offset="0.26" stop-color="#FFA51F" />
          <stop offset="0.465" stop-color="#F9ED2A" />
          <stop offset="0.725" stop-color="#64EC82" />
          <stop offset="1" stop-color="#36E6E6" />
        </linearGradient>
        <clipPath id="clip0_8_56">
          <rect width="343" height="262" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
