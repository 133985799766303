import styles from "./styles.module.scss";

export const Tooltip = ({ icon, title, content, background }) => {
  return (
    <div className={`${styles.tooltip} ${styles[background]}`}>
      <div className={styles.title}>
        <img src={icon} />
        <h6>{title}</h6>
      </div>
      <div className={styles.content}>
        <p>{content}</p>
      </div>
    </div>
  );
};
