import { CircularProgressbar } from "react-circular-progressbar";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const CreatingPlan = () => {
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        } else {
          clearInterval(interval);
          navigate('/email');
          return 100;
        }
      });
    }, 50);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.progress}>
        <CircularProgressbar
          strokeWidth={4}
          styles={{
            trail: {
              color: "#F8F8F8",
              stroke: "#F8F8F8",
            },
            root: {
              borderRadius: "50%",
              backgroundColor: "white",
            },
            path: {
              stroke: "var(--primary)",
              color: "var(--primary)",
            },
            text: {
              fontWeight: "700",
              fill: "black",
              fontSize: "18px",
            },
          }}
          value={progress}
          text={`${progress}%`}
        />
      </div>
      <div className={styles.subtitle}>Creating your personalized plan</div>
      <div className={styles.reviewContainer}>
        <div className={styles.people}>
          <h2>150 million people</h2>
          <p>Become the one to choose a healthy lifestyle with EasyFit</p>
        </div>
        <div className={styles.review}>
          <div className={styles.stars}>⭐⭐⭐⭐⭐</div>
          <div className={styles.title}>
            <strong>I love the app!</strong>
            Catherine
          </div>
          <div className={styles.paragraph}>
            I'm pleased and surprised by the variety of exercises. It's been 2
            weeks and I already see a difference in my legs and abs! As a
            beginner I find instructional videos clear and motivating although
            I'm sure someone more experienced will also appreciate custom
            workout plans and insightful wellness tips. Thanks for keeping me on
            track and inspired for the first time in forever!
          </div>
        </div>
      </div>
    </div>
  );
};
