import { useState } from "react";
import authService from "@api/services/authService";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const useEmail = (submitFunction, onNextStep) => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (value) => {
    const cleanedEmail = value.trim();
    setEmail(cleanedEmail);

    if (cleanedEmail.length < 5) {
      setIsValid(false);
      return false;
    }

    if (cleanedEmail.length > 254) {
      setIsValid(false);
      return false;
    }

    if (!emailRegex.test(cleanedEmail)) {
      setErrorMessage("Invalid email format.");
      setIsValid(false);
      return false;
    }

    setErrorMessage("");
    setIsValid(true);
    return true;
  };

  const setError = (message) => {
    setErrorMessage(message);
    setIsValid(false);
  };

  const handleEmailChange = (value) => {
    validateEmail(value);
  };

  const onSubmit = async (isValid, data) => {
    if (isLoading || !isValid) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      await submitFunction(data);
      onNextStep();
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong. Please try again."
        );
      } else {
        setErrorMessage("Network error. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    email,
    isValid,
    errorMessage,
    isLoading,
    handleEmailChange,
    setError,
    onSubmit,
  };
};
