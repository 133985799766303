import ok from "@assets/img/emoji/ok.png";
import exclamation from "@assets/img/emoji/exclamation.png";
import doubleexclamation from "@assets/img/emoji/doubleexclamation.png";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";

const getBmiInfo = (index) => {
  let bmiType = "normal";

  if (index <= 18) {
    bmiType = "underweight";
  } else if (index >= 26 && index <= 30) {
    bmiType = "overweight";
  } else if (index > 30) {
    bmiType = "obese";
  }

  const BMIMap = {
    normal: {
      icon: ok,
      paragraph:
        "You are doing a good job keeping your weight in the normal range. We will use your index to tailor a program to your needs.",
      background: "normal",
    },
    underweight: {
      icon: exclamation,
      paragraph:
        "Focus on muscle toning and a balanced diet. Stay positive and try to keep your daily calorie consumption in the recommended range.",
      background: "warning",
    },
    overweight: {
      icon: exclamation,
      paragraph:
        "You should pay more attention to your weight. We will use your index to tailor a weight loss program.",
      background: "warning",
    },
    obese: {
      icon: doubleexclamation,
      paragraph:
        "You should try to focus on your weight. We will use your index to tailor a weight loss program.",
      background: "attention",
    },
  };

  return { bmiInfo: BMIMap[bmiType], bmiType };
};

export const BMI = ({ bmi }) => {
  const { bmiInfo, bmiType } = getBmiInfo(bmi);
  const title = `Your BMI is ${bmi}, which is considered ${bmiType}`;
  return (
    <Tooltip
      icon={bmiInfo.icon}
      title={title}
      content={bmiInfo.paragraph}
      background={bmiInfo.background}
    />
  );
};
