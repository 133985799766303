import { useDispatch, useSelector } from "react-redux";
import {
  resetUserProgress,
  logoutUser,
  updateTraining,
  getTrainings,
  getTrainingProgress,
} from "../../../../redux/slices/exerciseSlice";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import SubmitButton from "../../../../common/SubmitButton";

import subscriptionPlanService from "../../../../api/services/subscriptionPlanService";

import styles from "./styles.module.scss";
import { Button } from "../../../../common";
import { successToast } from "../../../../utils/toaster";

export const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trainingInfo } = useSelector((state) => state.exerciseSlice);
  const isLoadingResetProgress = useSelector(
    (state) => state.excerciseSlice?.isLoadingResetProgress
  );

  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const response = await subscriptionPlanService.getSubscriptionActive();

      if (response?.length > 0 && response[0].state !== "CANCELLED") {
        setSubscriptionData(response[0]);
      } else {
        setSubscriptionData(null);
      }
    };

    checkSubscriptionStatus();
  }, []);

  const handleCancelSubscription = async () => {
    if (subscriptionData) {
      const subscriptionId = subscriptionData.id;

      const cancelSubscription =
        await subscriptionPlanService.cancelSubscriptionById(subscriptionId);

      if (cancelSubscription) {
        setSubscriptionData(null);
      }
    }
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      console.log("beforeinstallprompt triggered");
      e.preventDefault(); // Prevent default install prompt
      setDeferredPrompt(e); // Save the event for later use
      setShowInstallButton(true); // Show your custom install button
    };

    // Listen for beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the default installation prompt
      const { outcome } = await deferredPrompt.userChoice; // Wait for the user's decision
      if (outcome === "accepted") {
        console.log("User accepted the install prompt.");
      } else {
        console.log("User dismissed the install prompt.");
      }

      // After showing the prompt, reset the deferred prompt
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  const handleReset = async () => {
    await dispatch(updateTraining({ id: trainingInfo?.id, progress: 0 }));
    await dispatch(getTrainings());
    await dispatch(getTrainingProgress());
    successToast({ message: "Your progress has been reset" });
  };

  return (
    <div className={styles.profileSettingsContainer}>
      <p className={styles.profileSettingsTitle}>Profile</p>
      <SubmitButton
        onClick={handleReset}
        title="Reset progress"
        type="button"
        isLoading={isLoadingResetProgress}
        variant="greenBackground"
      />
      <button
        onClick={() => {
          localStorage.removeItem("token");
          dispatch(logoutUser());
          navigate("/auth");
        }}
        className={styles.logOutButton}
      >
        Log out
      </button>
      {showInstallButton && (
        <Button onClick={handleInstallClick}>Install App</Button>
      )}
      {subscriptionData && subscriptionData.state !== "CANCELLED" && (
        <button
          className={styles.cancelSubscription}
          onClick={handleCancelSubscription}
        >
          Cancel subscription
        </button>
      )}
    </div>
  );
};
