import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "./redux/store";
import ModalManager from "../src/common/modalManager";
import { Toaster } from "react-hot-toast";
import NiceModal from "@ebay/nice-modal-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense>
    <Provider store={store}>
      <NiceModal.Provider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>EasyFit</title>
        </Helmet>
        <Toaster containerClassName="toaster-wrapper" />
        <App />
        <ModalManager />
      </NiceModal.Provider>
    </Provider>
  </Suspense>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}


