import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "@common/index";
import { ftInToFt, ftToIn, kgToLbs } from "@utils/measurements";
import { BMI } from "./BMI";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { UserService } from "@api/services/userService";
import { getUserInfoThunk } from "src/redux/slices/userSlice";

const Measures = {
  us: "lbs",
  eu: "kg",
};

export const Weight = ({ onNextStep }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");
  const [weight, setWeight] = useState();
  const [isValid, setIsValid] = useState(false);
  const [bmi, setBmi] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setWeight("");
      setIsValid(false);
      return;
    }

    if (value.length <= 3 && !isNaN(value)) {
      const numericValue = Number(value);
      setWeight(numericValue);

      if (ms === "us") {
        setIsValid(numericValue <= 662 && numericValue >= 55);
      } else {
        setIsValid(numericValue <= 300 && numericValue >= 25);
      }
    }
  };

  const handleNextStep = async () => {
    const key = ms === "us" ? "qqWeightLbs" : "qqWeightKg";

    try {
      setLoading(true);
      await UserService.updateQuestions({ [key]: weight });
      onNextStep();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const height =
      ms === "us"
        ? ftToIn(
            ftInToFt(user?.questions?.qqHeightFt, user?.questions?.qqHeightIn)
          )
        : +user?.questions?.qqHeightCm / 100;

    const bmiCalculated = +weight / (height * height);
    const bmiToState = Math.round(
      ms === "us" ? bmiCalculated * 703 : bmiCalculated
    );

    setBmi(bmiToState);
  }, [weight]);

  useEffect(() => {
    dispatch(getUserInfoThunk());
  }, []);

  const height =
    ms === "us"
      ? ftToIn(
          ftInToFt(user?.questions?.qqHeightFt, user?.questions?.qqHeightIn)
        )
      : +user?.questions?.qqHeightCm / 100;

  const a = ftInToFt(user?.questions?.qqHeightFt, user?.questions?.qqHeightIn);

  console.log(height, user);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What's your current weight?</h2>
        <div className={styles.input}>
          <Input
            inputmode="numeric"
            type="number"
            step="1"
            placeholder={"Your weight"}
            value={weight}
            onChange={handleChange}
            endAdornment={<h5>{Measures[ms]}</h5>}
          />

          <p className={styles.hint}>
            Please, enter a value from{" "}
            <span>
              {ms === "eu"
                ? `${25} kg to ${300} kg`
                : `${kgToLbs(25).toFixed(0)} lbs to ${kgToLbs(300).toFixed(
                    0
                  )} lbs`}
            </span>
          </p>
        </div>
        {isValid && <BMI bmi={bmi} />}
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid || loading}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
