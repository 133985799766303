import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Clock } from "@assets/Icons";

export const CountdownTimer = ({ initialMinutes = 5, initialSeconds = 0 }) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  return (
    <div className={styles.countdownTimer}>
      <Clock />
      <p>
        THIS OFFER ENDS IN{" "}
        {`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`}{" "}
        MIN
      </p>
    </div>
  );
};
