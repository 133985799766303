import styles from "./styles.module.scss";
import img from "./img.png";
import { IconCard } from "../../../../shared/components";

const data = [
  {
    displayValue: "10-15 min",
    value: "10-15",
  },
  {
    displayValue: "15-20 min",
    value: "15-20",
  },
  {
    displayValue: "20-25 min",
    value: "20-25",
  },
  {
    displayValue: "25+ min",
    value: "25+",
  },
];

export const SpareForSelfCare = ({ onNextStep }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.img}>
          <img src={img} alt="finger" />
        </div>
        <h5>How much time can you spare for self-care each day?</h5>
        <div className={styles.cards}>
          {data.map((item) => (
            <IconCard
              key={item.value}
              onClick={() => onNextStep(item.value)}
              title={item.displayValue}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
