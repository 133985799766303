import styles from "./styles.module.scss";
import laurelLeft from "./img/laurelLeft.png";
import laurelRight from "./img/laurelRight.png";
import bg from "./img/bg.png";
import { Button } from "@common/button";
import { useNavigate } from "react-router";

export const PreRegistration = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>Take the first step towards your goals</h2>

        <div className={styles.rating}>
          <div className={styles.ratingWrapper}>
            <img src={laurelLeft} alt="laureLeft" />
            <div>
              <span>120K+</span>
              <div className={styles.stars}>
                <span>★ ★ ★ ★ ★</span>
              </div>
              <p className={styles.ratingText}>5-star rating</p>
            </div>
            <img src={laurelRight} alt="laureRight" />
          </div>
        </div>

        <div className={styles.imageContainer}>
          <img src={bg} alt="bg" />
        </div>

        <p className={styles.subtitle}>Create an account to save your data</p>
      </div>
      <div className={styles.action}>
        <Button onClick={() => navigate("/email")}>CONTINUE</Button>
      </div>
    </div>
  );
};
