import { IconCard } from "../iconCard";
import styles from "./styles.module.scss";

export const OptionsWithBackground = ({
  data,
  background,
  onClick,
  title,
  hint,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={styles.container}
    >
      <h2>{title}</h2>
      <div className={styles.cards}>
        {data.map((item) => (
          <IconCard
            img={item.emoji}
            onClick={() => onClick(item)}
            key={item.value}
            className={styles.card}
            title={item.displayValue}
          />
        ))}
        {hint && <div className={styles.hint}>{hint}</div>}
      </div>
    </div>
  );
};
