import styles from "./styles.module.scss";
import usatoday from "./img/usatoday.svg";
import forbes from "./img/forbes.svg";
import nyp from "./img/nyp.svg";
import mashable from "./img/mashable.svg";
import wsj from "./img/wsj.svg";

export const Partners = () => {
  return (
    <div className={styles.journey}>
      <p>Your journey to health starts here!</p>
      <div className={styles.logos}>
        <img src={usatoday} alt="USA Today" />
        <img src={forbes} alt="Forbes" />
        <img src={wsj} alt="WSJ" />
        <img src={nyp} alt="New York Post" />
        <img src={mashable} alt="Mashable" />
      </div>
    </div>
  );
};
