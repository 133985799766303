export const cmToFt = (cm) => {
  return +cm / 30.48;
};

export const ftToCm = (ft) => {
  return Math.round(+ft * 30.48);
};

export const ftToFtIn = (value) => {
  const ft = Math.abs(value);
  const inch = (ft - Math.round(value)) * 12;
  console.log(ft,inch)
  return { ft: Math.round(ft), inch };
};

export const ftInToFt = (ft, inch) => {
  if(!ft && !inch) {
    return 0;
  }
  return ft + +inch / 12;
};

export const ftToIn = (ft) => {
  return ft * 12;
}

export const kgToLbs = (kg) => {
  return +kg * 2.205;
};

export const lbsToKg = (lbs) => {
  return +lbs / 2.205;
};
