import { BreakdownWithEmoji, SOMATIC_KEYS } from "../../../../shared";
import applouse from "@assets/img/emoji/applouse.png";

const data = [
  {
    title: "You’re ahead of 91% of users",
    paragraph: [
      "Somatic exercises can help you <strong>get back in shape</strong> through mind-body balance.",
      "It's basically self-care in motion, because fitness should be enjoyable.",
    ],
  },
  {
    title: "You’re ahead of 73% of users",
    paragraph: [
      "You’ve been in great shape before, and we’re here to help you get there again. ",
      "Somatic exercises are beginner-friendly, gentle on your body, and <strong>easy to fit into your routine.</strong>",
    ],
  },
  {
    title: "You’re ahead of 62% of users",
    paragraph: [
      "With the help of somatic exercises, you can <strong>get in better shape.</strong> No stress, no gym.",
      "This mindful fitness approach is beginner-friendly, gentle on your body, and easy to add to your daily routine.",
    ],
  },
  {
    title: "You are not alone!",
    paragraph: [
      "11% of users feel the same way. But it doesn't mean you never will!",
      "Try somatic exercises — a perfect mix of mindfulness and gentle movement that <strong>easily becomes a daily habit.</strong>",
    ],
  },
];

export const YouAreAhead = ({ onNextStep }) => {
  const stepTextKey = localStorage.getItem(SOMATIC_KEYS.bestShape);

  const { paragraph, title } = data[+stepTextKey - 1];

  return (
    <BreakdownWithEmoji
      title={title}
      paragraph={paragraph}
      emoji={applouse}
      onContinue={() => onNextStep()}
    />
  );
};
