import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import startButton from "../../../../assets/startButton.svg";

export const TrainingPreview = ({
  isCompleted,
  activeDay,
  activeDayIndex,
  userDaysProgress,
}) => {
  const navigate = useNavigate();

  const previewImg = activeDay?.exercises[0].previewPicture;

  return (
    <div className={styles.previewContainer}>
      <div
        className={
          isCompleted
            ? `${styles.preview} ${styles.completed}`
            : `${styles.preview}`
        }
      >
        {isCompleted && <span>Done!</span>}
        <img src={previewImg} alt="preview" />
      </div>

      <button
        className={styles.startButton}
        onClick={() => {
          navigate(`/day-exercises/${activeDayIndex}`);
        }}
      >
        <img src={startButton} alt="" />
        <p>
          {userDaysProgress > activeDayIndex ? "Repeat" : "Start"} day{" "}
          {activeDayIndex}
        </p>
      </button>
    </div>
  );
};
