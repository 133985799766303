import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useFetchPlans } from "../hooks/useFetchPlans";
import { useNavigate } from "react-router";
import { errorToast, successToast } from "@utils/toaster";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { axiosInstance } from "@api/index";
import { updateDataLayer } from "../utils/updateDataLayer";

export const ApplePayButton = ({ id }) => {
  const user = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { initiatePayment, activePlan } = useFetchPlans();

  const onConfirm = async () => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      errorToast({ message: submitError.message });
      return;
    }

    const getStripeClientSecret = async () => {
      try {
        const { stripeClientSecret } = await initiatePayment({
          paymentMethod: "APPLE_PAY",
          paymentProvider: "STRIPE",
        });
        return stripeClientSecret;
      } catch (error) {
        errorToast({ message: error.response.data.message });
      }
    };

    const clientSecret = await getStripeClientSecret();

    const payload = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://easyfit.me",
      },
    });

    if (payload.error) {
      errorToast({ message: payload.error.message });
    } else {
      await axiosInstance.post("/auth/send-pin-to-email", {
        email: user?.email,
      });
      updateDataLayer({
        paymentMethod: "APPLE_PAY",
        plan: activePlan.title,
        currency: activePlan.currency,
        price: +activePlan.priceAmount / 100,
      });
      navigate("/activate");

      //hide upsale
      // if (process.env.REACT_APP_PRODUCT === "SOMATIC") {
      //   navigate("/upsale");
      // } else {
      //   navigate("/activate");
      // }
      successToast({ message: "Payment successful! Thank you!" });
    }
  };

  return (
    <>
      <ExpressCheckoutElement
        className={`${styles.applepay} ${id}`}
        onConfirm={onConfirm}
      />
    </>
  );
};
