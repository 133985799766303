import { Button } from "@common";
import { Loader } from "@common/loader";
import { useGetUserInfo } from "@hooks/useGetUserProfile";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import {
  ChartGain,
  ChartLose,
  ChartMaintain,
} from "src/routes/onboarding/shared/components/planChart";

export const PlanChart = () => {
  const { user, loaded } = useGetUserInfo();
  const ms = localStorage.getItem("ms");
  const name = localStorage.getItem("name");
  const navigate = useNavigate();

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const isLoseWeight = weight > goal && weight !== goal;
  const isGainWeight = weight < goal && weight !== goal;

  const goalText = isLoseWeight ? "to lose weight" : "to get toned & fit";

  return (
    <>
      {loaded ? (
        <div className={styles.container} id="breakdown">
          <div className={styles.content}>
            <h2>
              <span>{name},</span> <br /> your 4-week customized Somatic
              exercise plan <span>{goalText}</span> is here!
            </h2>

            <div
              className={
                loaded ? styles.chart : `${styles.chart} ${styles.hidden}`
              }
            >
              <div className={styles.title}>Your Weight</div>
              <div className={styles.axisx}>
                <span>Week 1</span>
                <span>Week 2</span>
                <span>Week 3</span>
                <span>Week 4</span>
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.now}`
                    : isGainWeight
                    ? `${styles.now} ${styles.gain}`
                    : `${styles.now} ${styles.maintain}`
                }
              >
                Now
              </div>
              <div
                className={
                  isLoseWeight
                    ? `${styles.after}`
                    : isGainWeight
                    ? `${styles.after} ${styles.gain}`
                    : `${styles.after} ${styles.maintain}`
                }
              >
                After <br />4 weeks
              </div>

              {isLoseWeight ? (
                <ChartLose />
              ) : isGainWeight ? (
                <ChartGain />
              ) : (
                <ChartMaintain />
              )}
            </div>
            <div className={styles.hint}>
              The chart is for visualization only
            </div>
          </div>

          <div className={styles.action}>
            <Button onClick={() => navigate("/paywall")}>Continue</Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
