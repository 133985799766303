import styles from "./styles.module.scss";
import normal from "./img/normal.png";
import average from "./img/average.png";
import overweight from "./img/overweight.png";
import obese from "./img/obese.png";

import { ImageCard } from "../../../../shared";

import { useNavigate } from "react-router";

const data = [
  {
    value: "Normal",
    displayValue: "Normal",
    emoji: normal,
  },
  {
    value: "Average",
    displayValue: "Average",
    emoji: average,
  },
  {
    value: "overweight",
    displayValue: "overweight",
    emoji: overweight,
  },
  {
    value: "Obese",
    displayValue: "Obese",
    emoji: obese,
  },
];
export const ChooseBodyType = ({ onNextStep }) => {

  const handleNextStep = () => {
    onNextStep?.();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Choose your current body type</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <ImageCard
              onClick={(v) => handleNextStep(v)}
              img={value.emoji}
              text={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
