import { Button, Input } from "@common/index";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Calendar, Lock } from "@assets/Icons";
import { SOMATIC_KEYS } from "src/routes/onboarding/shared";

export const EventDate = ({ onNextStep }) => {
  const [date, setDate] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    setDate(value);
    setIsValid(new Date(value).getTime() >= new Date().getTime());
  };

  const handleNextStep = () => {
    onNextStep(date);
  };

  const handleSkip = () => {
    onNextStep(null);
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>When is this occasion?</h2>
        <p className={styles.subtitle}>
          We will keep track of this event for you
        </p>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <Input
              min={new Date().toISOString().split("T")[0]}
              type="date"
              value={date}
              onChange={handleChange}
              endAdornment={<Calendar />}
            />
          </div>
          <p className={styles.hint}>
            <Lock /> We care about your privacy and are dedicated to protecting
            your personal information.
          </p>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!date || !isValid}>
          Next Step
        </Button>
        <button className={styles.skip} onClick={handleSkip}>
          Skip this step
        </button>
      </div>
    </div>
  );
};
