import styles from "./styles.module.scss";
import chart from "./img/chart.png";
import { Button } from "@common/button";

export const UpsaleModal = ({ onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.container}>
        <div className={styles.modalContent}>
          <h3>Did you know?</h3>
          <p className={styles.text}>
            <span>65%</span> of users who started their{" "}
            <span>EasyFit Plan</span> advanced in their goals within the{" "}
            <span>first month*</span>
          </p>
          <div className={styles.chart}>
            <img src={chart} alt="Chart" />
          </div>
          <p className={styles.disclaimer}>
            * Based on the data of users who log their progress in the app
          </p>
          <div className={styles.offerText}>
            We want you to find success so we are offering the{" "}
            <span>additional discount</span> on your{" "}
            <span>Somatic Exercise Plan.</span>
          </div>
        </div>
        <div>
          <Button onClick={handleClose}>GOT IT</Button>
        </div>
      </div>
    </div>
  );
};
