import styles from "./styles.module.scss";
import {
  Fire,
  Salad,
  ShoulderBack,
  ThumbsUp,
  YogaStretching,
} from "@assets/Icons";

export const Benefits = () => {
  return (
    <div className={styles.benefits}>
      <p className={styles.title}>Highlights of your plan</p>
      <div className={styles.list}>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <Fire />
          </div>
          <p>Toned up body: get in shape quickly and easily</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <ShoulderBack />
          </div>

          <p>10-min high-efficiency workout sets for any body area you need</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <ThumbsUp />
          </div>

          <p>Alleviated tension all throughout the body</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <YogaStretching />
          </div>

          <p>100+ exercises to improve flexibility and mobility</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <Salad />
          </div>

          <p>Custom program to follow every day</p>
        </div>
      </div>
    </div>
  );
};
