import { axiosInstance } from "..";

export const WorkoutContentService = {
  getUserSubscriptions: async () => {
    const response = await axiosInstance.get("/me/subscriptions/active");
    return response.data[0].subscriptionPlan.products || [];
  },
  get: (product) => {
    return axiosInstance.get(`/content/workouts?product=${product}`);
  },
};
