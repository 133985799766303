import NiceModal from "@ebay/nice-modal-react";
import { ModalContainer } from "../common/modalContainer";

export const closeModal = () => {
  NiceModal.hide(ModalContainer);
};

export const openModal = (children) =>
  NiceModal.show(ModalContainer, {
    onClose: closeModal,
    children,
  });
