import {
  PeopleChosen,
  Initiate,
  MainGoal,
  TransformBody,
  HopeToAchieve,
  FeelingStressed,
  FeelingExhausted,
  CryingLastTime,
  UnressolvedEmotions,
  BrainFog,
  EmotionallyAffected,
  LastTimeRelaxed,
  RelaseYourself,
  StruggleSetbacks,
  PeopleGiveUp,
  BodyImage,
  ChooseBodyType,
  CompositionWantToHave,
  HowWeightChange,
  ReadyToLose,
  WithoutStress,
  ChooseTargetZones,
  LoadingChart,
  ChooseFocus,
  SciaticaPain,
  Headaches,
  SufferFromConditions,
  TakingMedications,
  BackProblems,
  SensitiveAreas,
  ThankForSharing,
  TensionInBody,
  TensionFeel,
  ReleaseTension,
  StruggleSleep,
  SleepIsKey,
  OftenWorkout,
  YouAwesome,
  KnowAboutSomatic,
  HowFlexible,
  YouAreAhead,
  BestShape,
  SelfCareTroubles,
  TriedYoga,
  TypicalDay,
  SpareForSelfCare,
  WeKnow,
} from "../questions";
import { Age } from "../questions/age";
import { BiggestMotivation } from "../questions/biggestMotivation";
import { EventDate } from "../questions/eventDate";
import { FutureEvent } from "../questions/futureEvent";
import { Height } from "../questions/height";
import { Report } from "../questions/report";
import { Weight } from "../questions/weight";
import { WeightGoal } from "../questions/weightGoal";

export const groupTitles = [
  "Goals",
  "Emotional profile",
  "Body profile",
  "Activity",
  "Almost there",
  "Almost Done",
];

export const Steps = [
  { groupIndex: 0, component: Initiate },
  { groupIndex: 0, component: PeopleChosen },
  { groupIndex: 0, component: MainGoal, headerTitle: "Goals" },
  { groupIndex: 0, component: WeKnow },
  {
    groupIndex: 0,
    component: HopeToAchieve,
    headerTitle: "Goals",
  },

  {
    groupIndex: 1,
    component: TransformBody,
  },
  {
    groupIndex: 1,
    component: FeelingStressed,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: FeelingExhausted,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: CryingLastTime,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: UnressolvedEmotions,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: BrainFog,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: EmotionallyAffected,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: LastTimeRelaxed,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: RelaseYourself,
  },
  {
    groupIndex: 1,
    component: StruggleSetbacks,
    headerTitle: "Emotional profile",
  },
  {
    groupIndex: 1,
    component: PeopleGiveUp,
  },
  {
    groupIndex: 2,
    component: BodyImage,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: ChooseBodyType,
    headerTitle: "Body Profile",
  },

  {
    groupIndex: 2,
    component: CompositionWantToHave,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: HowWeightChange,
    headerTitle: "Body Profile",
    mappingKey: "qqWeightFluctuation",
  },

  {
    groupIndex: 2,
    component: ReadyToLose,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: WithoutStress,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: ChooseFocus,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: ChooseTargetZones,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: LoadingChart,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: SciaticaPain,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: Headaches,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: SufferFromConditions,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: TakingMedications,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: BackProblems,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: SensitiveAreas,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: ThankForSharing,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: TensionInBody,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: TensionFeel,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 2,
    component: ReleaseTension,
    headerTitle: "Body Profile",
  },
  {
    groupIndex: 3,
    component: StruggleSleep,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: SleepIsKey,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: OftenWorkout,
    headerTitle: "Activity",
    mappingKey: "qqExerciseFrequency",
  },
  {
    groupIndex: 3,
    component: YouAwesome,
  },
  {
    groupIndex: 3,
    component: KnowAboutSomatic,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: TriedYoga,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: HowFlexible,
    headerTitle: "Activity",
    mappingKey: "qqFlexibility",
  },
  {
    groupIndex: 3,
    component: BestShape,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: YouAreAhead,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: SelfCareTroubles,
    headerTitle: "Activity",
  },
  {
    groupIndex: 3,
    component: TypicalDay,
    headerTitle: "Activity",
    mappingKey: "qqDayRoutine",
  },
  {
    groupIndex: 3,
    component: SpareForSelfCare,
    headerTitle: "Activity",
  },
  {
    groupIndex: 4,
    component: BiggestMotivation,
    headerTitle: "Almost Done",
  },
  {
    groupIndex: 4,
    component: Height,
    headerTitle: "Almost Done",
  },
  {
    groupIndex: 4,
    component: Weight,
    headerTitle: "Almost Done",
  },
  {
    groupIndex: 4,
    component: WeightGoal,
    headerTitle: "Almost Done",
  },
  {
    groupIndex: 4,
    component: Age,
    headerTitle: "Almost Done",
    mappingKey: "qqAge",
  },
  {
    groupIndex: 4,
    component: Report,
    headerTitle: "Almost Done",
  },
  {
    groupIndex: 4,
    component: FutureEvent,
    headerTitle: "Almost Done",
    mappingKey: "qqOccasion",
  },
  {
    groupIndex: 4,
    component: EventDate,
    headerTitle: "Almost Done",
    mappingKey: "qqOccasionDate",
  },
];
