import { PersonCircle } from "@assets/Icons";
import { Button, Input } from "@common/index";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";
import { SOMATIC_KEYS } from "src/routes/onboarding/shared";

const title = "We ask your age to create your personal plan";
const content =
  "Older people tend to have more body fat than younger people with the same BMI.";

export const Age = ({ onNextStep }) => {
  const [age, setAge] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const value = e.currentTarget.value;

    if (value === "") {
      setAge("");
      setIsValid(false);
      return;
    }

    if (value.length <= 2 && !isNaN(value)) {
      const numericValue = Number(value);
      setAge(numericValue);

      setIsValid(numericValue <= 80 && numericValue >= 18);
    }
  };

  const handleNextStep = () => {
    console.log(age);
    onNextStep(age);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>What is your age?</h2>
        <div className={styles.input}>
          <Input
            inputmode="numeric"
            type="number"
            step="1"
            placeholder={"Age"}
            value={age}
            onChange={handleChange}
            endAdornment={<h5>years</h5>}
          />

          <p className={styles.hint}>
            Please, enter a value from <span>18 to 80</span>
          </p>
        </div>
        <Tooltip
          icon={fingerup}
          title={title}
          content={content}
          background="info"
        />
      </div>
      <div className={styles.action}>
        <Button onClick={handleNextStep} disabled={!isValid}>
          Next Step
        </Button>
      </div>
    </div>
  );
};
