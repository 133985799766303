import { DATA } from "src/routes/onboarding/default/stepper/questions/futureEvent";

export const getPredictionData = (user) => {
  const ms = localStorage.getItem("ms");

  const weight =
    ms === "us" ? user?.questions?.qqWeightLbs : user?.questions?.qqWeightKg;
  const goal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  const predictedWeight =
    ms === "us"
      ? user?.fitnessProfile?.weightLbsEstimateByOccasionDate
      : user?.fitnessProfile?.weightKgEstimateByOccasionDate;

  const predictedDate = user?.fitnessProfile?.weightGoalCompletionEstimatedDate;
  const eventResponse = user?.questions.qqOccasion;
  const event = DATA.find((item) => item.value === eventResponse);
  const eventDate = user?.questions?.qqOccasionDate;

  const isLoseWeight = weight > goal && weight !== goal;
  const isGainWeight = weight < goal && weight !== goal;

  const averageWeight = Math.round((weight + goal) / 2);
  const gainWeightMiddle = Math.round(weight + (averageWeight - weight) * 1.5);
  const gainWeightEnd = weight + (averageWeight - weight) * 3;

  const weightChange =
    ms === "us"
      ? user?.fitnessProfile?.monthlyEstimatedWeightChangeLbs
      : user?.fitnessProfile?.monthlyEstimatedWeightChangeKg;

  const axisY = isLoseWeight
    ? [weight, averageWeight, goal]
    : [gainWeightEnd, gainWeightMiddle, weight];

  return {
    weight,
    goal,
    predictedWeight,
    predictedDate,
    event,
    eventResponse,
    eventDate,
    isLoseWeight,
    isGainWeight,
    weightChange,
    axisY,
  };
};
