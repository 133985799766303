import { goalMapping } from "src/routes/onboarding/shared/components/paywall/components/plan/utils/goalMapping";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import slim from "./img/slim.png";
import { useGetFitnessProfile } from "@hooks/useGetFitnessProfile";

export const ProfileCard = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const { profile } = useGetFitnessProfile();
  const questions = userInfo?.questions;
  const goal = goalMapping[questions?.qqGoal] || "Lose weight";

  return (
    <div className={styles.profileCard}>
      <h2>This plan is based on your needs and parameters:</h2>
      <div className={styles.wrapper}>
        <div className={styles.profileChart}>
          <div className={styles.clouds}>
            <div className={styles.cloud}>
              <p>Level</p> <h5>{profile?.fitnessLevel}</h5>
            </div>
            <div className={styles.cloud}>
              <p>Body type</p> <h5>{profile?.bodyType}</h5>
            </div>
            <div className={styles.cloud}>
              <p>Age</p> <h5>{questions?.qqAge}</h5>
            </div>
          </div>
          <img src={slim} className={styles.bodyImg} />
          <div className={styles.goal}>
            <div className={styles.goalCloud}>{goal}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
