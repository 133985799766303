import { GrayKeyboardArrow } from "@assets/Icons";
import styles from "./styles.module.scss";

export const Header = ({ onSkip }) => {
  return (
    <header className={styles.header}>
      <p className={styles.logo}>EasyFit</p>
      <button className={styles.skipButton} onClick={onSkip}>
        SKIP <GrayKeyboardArrow />
      </button>
    </header>
  );
};
