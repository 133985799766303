import styles from "./styles.module.scss";
import tired from "@assets/img/emoji/tired.png";
import calm from "@assets/img/emoji/calm.png";
import thinking from "@assets/img/emoji/thinking.png";
import nice from "@assets/img/emoji/nice.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Yes, and it continues to affect me ",
    emoji: tired,
  },
  {
    value: "2",
    displayValue: "Yes, but it doesn’t affect me anymore",
    emoji: calm,
  },
  {
    value: "3",
    displayValue: "I‘m not sure",
    emoji: thinking,
  },
  {
    value: "4",
    displayValue: "No, life has been doing well",
    emoji: nice,
  },
];
export const EmotionallyAffected = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    localStorage.setItem(SOMATIC_KEYS.emotioanllyAffected, v);
    onNextStep(v);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>Has something recently affected you emotionally?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
