import { useEffect } from "react";
import styles from "./styles.module.scss";
import { Header } from "./components/header";
import { Stepper } from "./components/stepper/index";
import { ProductCard } from "./components/productCard";
import { CountdownTimer } from "./components/timer";
import { FeaturesList } from "./components/featuresList";
import { Title } from "./components/title";
import { Button } from "@common/button";
import { useDispatch, useSelector } from "react-redux";
import { getPlansThunk, setActivePlan } from "src/redux/slices/plans";
import { getAgreementTextUpsale } from "../../shared/components/paywall/components/plan/utils/getPlanDetails";
import { useNavigate } from "react-router";

export const Upsale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { upsalePlans, activePlan } = useSelector((state) => state.plans);

  useEffect(() => {
    dispatch(getPlansThunk());
  }, []);

  useEffect(() => {
    if (!activePlan) {
      dispatch(setActivePlan(upsalePlans[0]));
    }
  }, [dispatch, upsalePlans, activePlan]);

  const handleSkip = () => {
    navigate("/activate");
  };

  const agreementText = getAgreementTextUpsale(activePlan);

  return (
    <>
      <Header onSkip={handleSkip} />
      <div className={styles.layout}>
        <Stepper />
        <Title />
        <CountdownTimer />
        <div className={styles.productCards}>
          {upsalePlans.map((plan) => {
            return (
              <ProductCard
                key={plan.id}
                plan={plan}
                isDiscounted={true}
                checked={plan.id === activePlan?.id}
                onSelect={() => dispatch(setActivePlan(plan))}
              />
            );
          })}
        </div>
        <FeaturesList />
        <Button className={styles.getPlanButton}>GET MY PLAN</Button>
        <button className={styles.skipButton} onClick={handleSkip}>
          SKIP THIS AND CONTINUE ENROLLMENT
        </button>

        <p className={styles.note}>{agreementText}</p>
      </div>
    </>
  );
};
