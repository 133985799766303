import { Close, Share } from "../../../../assets/Icons";
import logo from "../../../../assets/img/png/logo.png";
import styles from "./styles.module.scss";

export const HomeScreenModal = ({ onClose }) => {
  const handleClose = () => {
    localStorage.setItem("userClosedAddModal", "true");
    onClose();
  };
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div onClick={handleClose} className={styles.close}>
          <Close />
        </div>
        <img src={logo} className={styles.logo} alt="logo" />
        <h3>Install EasyFit</h3>
        <p>Add EasyFit to your home screen to easily access it on the go</p>
        <ol>
          <li>
            1. Find share <Share /> button in your browser
          </li>
          <li>2. Tap "Add to home screen"</li>
        </ol>
      </div>
    </div>
  );
};
