import styles from "./styles.module.scss";
import heart from "@assets/img/emoji/heart.png";
import yoga from "@assets/img/emoji/yoga.png";
import massage from "@assets/img/emoji/massage.png";
import thinking from "@assets/img/emoji/thinking.png";

import { SOMATIC_KEYS } from "../../../../shared";

import { useNavigate } from "react-router";
import { IconCard } from "../../../../shared";

const data = [
  {
    value: "1",
    displayValue: "Quite recently",
    emoji: heart,
  },
  {
    value: "2",
    displayValue: "Within the last month",
    emoji: yoga,
  },
  {
    value: "3",
    displayValue: "A while ago",
    emoji: massage,
  },
  {
    value: "4",
    displayValue: "I don’t remember",
    emoji: thinking,
  },
];
export const LastTimeRelaxed = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    localStorage.setItem(SOMATIC_KEYS.lastTimeRelaxed, v);
    onNextStep?.(v);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2>When was the last time you truly felt relaxed?</h2>
        <div className={styles.cards}>
          {data.map((value) => (
            <IconCard
              onClick={() => handleNextStep(value.value)}
              img={value.emoji}
              title={value.displayValue}
              key={value.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
