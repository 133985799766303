import { Tooltip } from "src/routes/onboarding/shared/components/tooltip";
import fingerup from "@assets/img/emoji/fingerup.png";
import like from "@assets/img/emoji/like.png";
import thinking from "@assets/img/emoji/thinking.png";

const BMIHintsMap = {
  Normal: {
    icon: like,
    paragraph:
      "Based on research, 18-24% is an ideal body fat percentage for women to start gaining muscle and building a fit body faster.",
    title: "Good starting BMI to build a fit body",
    background: "normal",
  },
  Overweight: {
    icon: thinking,
    paragraph:
      "High blood pressure, heart disease, stroke, type 2 diabetes, some types of cancer, osteoarthritis, back pain, all-cause mortality.",
    title: "Risks for an unhealthy BMI",
    background: "attention",
  },
  Obese: {
    icon: thinking,
    paragraph:
      "High blood pressure, heart disease, stroke, type 2 diabetes, some types of cancer, osteoarthritis, back pain, all-cause mortality.",
    title: "Risks for an unhealthy BMI",
    background: "attention",
  },
  Underweight: {
    icon: fingerup,
    paragraph:
      "Being underweight can lead to weakened immune function and increased risk of other health issues",
    title: "Risk of low BMI",
    background: "info",
  },
};

export const BMIHint = ({ bmiType }) => {
  const BMIHintInfo = BMIHintsMap[bmiType] || BMIHintsMap.Underweight;
  return (
    <Tooltip
      icon={BMIHintInfo.icon}
      title={BMIHintInfo.title}
      content={BMIHintInfo.paragraph}
      background={BMIHintInfo.background}
    />
  );
};
