import { SOMATIC_KEYS } from "../../../../shared";
import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";
import { useNavigate } from "react-router";

const data = [
  { displayValue: "Quite recently", value: "1" },
  { displayValue: "Within the last month", value: "2" },
  { displayValue: "A while ago", value: "3" },
  { displayValue: "I don’t remember", value: "4" },
];

export const CryingLastTime = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
    localStorage.setItem(SOMATIC_KEYS.feelingStressed, v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="What was the last time you cried?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
