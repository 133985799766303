import { Button } from "@common/index";
import styles from "./styles.module.scss";

export const BreakdownWithEmoji = ({ onContinue, emoji, title, paragraph }) => {
  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <div className={styles.icon}>
          <img src={emoji} alt="finger" />
        </div>
        <h5>{title}</h5>
        {paragraph.map((item, index) => (
          <p dangerouslySetInnerHTML={{ __html: item }} key={index}></p>
        ))}
      </div>
      <div className={styles.action}>
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </div>
  );
};
