import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";

const data = [
  {
    value: "ADVANCED",
    displayValue: "Pretty flexeible ",
  },
  {
    value: "AVERAGE",
    displayValue: "Average",
  },
  {
    value: "LIMITED",
    displayValue: "Not flexible",
  },
  {
    value: "UNKNOWN",
    displayValue: "Not sure",
  },
];

export const HowFlexible = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    console.log(v.value);
    onNextStep?.(v.value);
  };
  return (
    <OptionsWithBackground
      data={data}
      title="How flexible are you?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
