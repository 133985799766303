import { useEffect, useState } from "react";

const useShowApplePayButton = () => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  useEffect(() => {
    const checkApplePay = async () => {
      if (
        window?.ApplePaySession &&
        typeof window.ApplePaySession.canMakePayments === "function"
      ) {
        try {
          const canPay = await window.ApplePaySession.canMakePayments();
          setIsApplePayAvailable(!!canPay);
        } catch (err) {
          console.error("Apple Pay check failed:", err);
          setIsApplePayAvailable(false);
        }
      } else {
        setIsApplePayAvailable(false);
      }
    };

    checkApplePay();
  }, []);

  return isApplePayAvailable;
};

export default useShowApplePayButton;
