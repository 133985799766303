import { useEffect, useRef } from "react";
import styles from "../../shared/components/paywall/styles.module.scss";

import Overweight from "./assets/img/overweight.png";
import Underweight from "./assets/img/underweight.png";
import Normal from "./assets/img/normal.png";
import Obese from "./assets/img/obese.png";
import After from "./assets/img/after.png";
import {
  FAQSection,
  Guarantee,
  Partners,
  PaywallChart,
  PersonalPlan,
  Plan,
  ResultsSlider,
  Reviews,
  Timer,
  Transform,
} from "../../shared/components/paywall/components";
import { DiscountProvider } from "../../shared/components/paywall/DiscountContext";
import { ProfileCard } from "./components/profileCard";
import { Benefits } from "./components/benefits";
import { useDispatch } from "react-redux";
import { getUserInfoThunk } from "src/redux/slices/userSlice";

const BMI_TYPE_IMAGES = {
  Normal,
  Overweight,
  Obese,
  Underweight,
  After,
};

export const Paywall = () => {
  const dispatch = useDispatch();
  const faqSectionRef = useRef(null);
  const element1Ref = useRef(null);
  const element2Ref = useRef(null);
  const element3Ref = useRef(null);

  useEffect(() => {
    dispatch(getUserInfoThunk());
  }, []);

  const elements = [element1Ref, element2Ref, element3Ref];

  const handleScrollToClosest = () => {
    const scrollTop = window.scrollY;

    const closestElement = elements.reduce((closest, current) => {
      const currentOffsetTop =
        current.current.getBoundingClientRect().top + scrollTop;
      const closestOffsetTop =
        closest.current.getBoundingClientRect().top + scrollTop;

      const currentDistance = Math.abs(currentOffsetTop - scrollTop);
      const closestDistance = Math.abs(closestOffsetTop - scrollTop);

      return currentDistance < closestDistance ? current : closest;
    });

    closestElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        <Timer onGetMyPlanClick={handleScrollToClosest} />
        <Transform images={BMI_TYPE_IMAGES} />
        <PersonalPlan />
        <div ref={element1Ref}>
          <Plan id="1" title="AI-optimized plan" isMetrics />
        </div>
        <ProfileCard />
        <PaywallChart />
        <div ref={element2Ref}>
          <Plan id="2" title="Get visible results in 4 weeks!" />
        </div>
        <Benefits />
        <Partners />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <div ref={element3Ref}>
          <Plan id="3" title="Get visible results in 4 weeks!" />
        </div>
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
    </DiscountProvider>
  );
};
