import styles from "./styles.module.scss";
import { Lock } from "@assets/Icons";
import { Button } from "@common/index";
import authService from "@api/services/authService";
import { useEmail } from "src/routes/onboarding/shared/hooks/useEmail";
import { useNavigate } from "react-router";

export const Email = () => {
  const navigate = useNavigate();
  const onNextStep = () => {
    navigate('/name')
  }
  const {
    email,
    isValid,
    errorMessage,
    handleEmailChange,
    isLoading,
    onSubmit,
  } = useEmail(authService.emailOnlySignUp, onNextStep);

  const handleSubmit = async () => {
    onSubmit(isValid, email);
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>
          Enter your email to receive
          <br />
          <span>Your Custom Program</span>
        </h2>
        <div className={styles.input}>
          <input
            placeholder="Your email"
            type="email"
            value={email}
            onChange={(e) => handleEmailChange(e.currentTarget.value)}
            className={isValid}
          />
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}{" "}
          <div className={styles.hint}>
            <div>
              <Lock />
            </div>
            <p>
              We care about your privacy and are dedicated to protecting your
              personal information. We'll send you a copy of your results for
              your convenience.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} disabled={!isValid || isLoading}>
          Continue
        </Button>
      </div>
    </div>
  );
};
