import { useParams } from "react-router";
import styles from "./styles.module.scss";
import { Steps } from "../data/steps";
import { Done } from "@assets/Icons";

export const ProgressBar = () => {
  const { stepIndex } = useParams();

  let currentStep;
  if (isNaN(stepIndex)) {
    currentStep = Steps.find((step) => step.uri === stepIndex);
  } else {
    const index = parseInt(stepIndex, 10) - 1;
    currentStep = Steps[index];
  }

  const currentStepIndex = Steps.indexOf(currentStep);

  const groupIndices = [...new Set(Steps.map((step) => step.groupIndex))];

  const currentGroupIndex = currentStep ? currentStep.groupIndex : 0;

  const groupData = groupIndices.map((groupIndex) => {
    const groupSteps = Steps.filter((step) => step.groupIndex === groupIndex);

    const isGroupCompleted =
      groupIndex < currentGroupIndex ||
      (groupIndex === currentGroupIndex &&
        currentStepIndex >= Steps.indexOf(groupSteps[0]));

    const completedStepsCount = groupSteps.reduce((count, step) => {
      const stepIndex = Steps.indexOf(step);
      return stepIndex <= currentStepIndex ? count + 1 : count;
    }, 0);

    const groupProgress = (completedStepsCount / groupSteps.length) * 100;

    return { groupIndex, isGroupCompleted, groupProgress };
  });

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar}>
        {groupData.map(
          ({ groupIndex, isGroupCompleted, groupProgress }, index) => (
            <div key={groupIndex} className={styles.stepGroup}>
              <div
                className={`${styles.circle} ${
                  isGroupCompleted ? styles.completed : ""
                }`}
              >
                {isGroupCompleted ? <Done /> : ""}
              </div>

              {index < groupData.length && (
                <div className={styles.progressBarSegmentContainer}>
                  <div
                    className={styles.progressBarSegment}
                    style={{
                      width: `${groupProgress}%`,
                    }}
                  />
                </div>
              )}
            </div>
          )
        )}
        <div className={styles.stepGroup}>
          <div className={styles.circle}></div>
        </div>
      </div>
    </div>
  );
};
