import { IconCard } from "@common/iconCard";
import styles from "./styles.module.scss";
import { Cross, AthleticsTeamRunning, Furniture } from "@assets/Icons";

const DATA = {
  MOSTLY_SITTING: {
    title: "I sit the majority of the day",
    icon: Furniture,
  },
  SITTING_BUT_ACTIVE_BREAKS: {
    title: "I try to be active on breaks",
    icon: AthleticsTeamRunning,
  },
  MOSTLY_ON_FEET: {
    title: "I'm on my feet all day long",
    icon: Cross,
  },
};

export const UsualDay = ({ onNextStep }) => {
  const handleNextStep = (value) => {
    console.log(value);
    onNextStep(value);
  };

  return (
    <div className={styles.container}>
      <h2>How would you describe your usual day?</h2>
      <div className={styles.cards}>
        {Object.entries(DATA).map(([key, { title, icon: Icon }]) => (
          <IconCard
            onClick={() => handleNextStep(key)}
            img={<Icon />}
            title={title}
            key={key}
          />
        ))}
      </div>
    </div>
  );
};
