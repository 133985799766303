import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Portal = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("bearerToken");
  useEffect(() => {
    localStorage.setItem("token", token);
    navigate("/auth");
  }, []);
  return <div />;
};
