import { useSelector } from "react-redux";
import { Like, Performance, Rewards, Star } from "@assets/Icons";
import styles from "./styles.module.scss";
import { formatDate } from "@utils/formatDate";
import useDiscountCountdown from "@hooks/useDiscountCountdown";

export const PersonalPlan = ({ isSomatic }) => {
  const {
    time: { displayMinutes, displaySeconds },
  } = useDiscountCountdown();

  const userInfo = useSelector((state) => state.user.userInfo);
  const weightMs = userInfo?.questions.qqGoalWeightKg ? "kg" : "lbs";
  const goalWeight =
    userInfo?.questions.qqGoalWeightKg || userInfo?.questions.qqGoalWeightLbs;
  const weight =
    userInfo?.questions.qqWeightKg || userInfo?.questions.qqWeightLbs;

  const isGainWeight = goalWeight > weight;

  return (
    <div className={styles.personalPlan}>
      <h2>
        Your personalized <br />
        {isSomatic ? <span>Somatic Exercise</span> : "fitness"} plan is ready!
      </h2>
      <div className={`${styles.card} ${styles.outlined}`}>
        <div className={styles.cardListItemWithIcon}>
          <div
            className={styles.icon}
            style={{ transform: isGainWeight ? "scaleY(-1)" : "scaleY(1)" }}
          >
            <Performance />{" "}
          </div>
          <p>
            <strong>
              {isGainWeight ? "Gain" : "Lose"} {Math.abs(goalWeight - weight)}{" "}
              {weightMs}
            </strong>{" "}
            by{" "}
            {formatDate(
              userInfo?.fitnessProfile.weightGoalCompletionEstimatedDate
            )}
            .
          </p>
        </div>
        <div className={styles.cardListItemWithIcon}>
          <div className={styles.icon}>
            <Rewards />
          </div>
          <p>
            Continue developing healthy habits to keep the weight off after
            reaching your goal
          </p>
        </div>
        <div className={styles.cardListItemWithIcon}>
          <div className={styles.icon}>
            <Like />
          </div>
          <p>
            {isSomatic
              ? "Release yourself from the hold of stress."
              : "Custom program to follow every day."}
          </p>
        </div>
      </div>
      <div className={`${styles.card} ${styles.filled}`}>
        <h3>Your personalized plan is reserved!</h3>
        <h4>
          We've reserved your {isSomatic && "Somatic"} exercise plan for 10
          minutes.
        </h4>
        <h4>
          <strong>
            EXPIRES IN:{" "}
            <span className={styles.countdown}>
              {displayMinutes}:{displaySeconds}
            </span>
          </strong>
        </h4>
      </div>
    </div>
  );
};
